<template>
    <div class="table-cbox">
        <div class="title">{{title}}</div>
        <div class="top-bar" v-if="showFunctionBar">
            <!-- <div class="download-table">下载表格</div> -->
            <div class="table-opt">
                <Select
                    v-for="(item,index) in tableOption"
                    :key="index"
                    style="width:200px;margin-right:5px;max-heigh:25px"
                    clearable
                    :multiple="item.multiple||false"
                    size="small"
                    @on-change="(value)=>changeOption(value,item)"
                    :placeholder="item.name"
                >
                    <Option
                        v-for="i in item.optionList"
                        :value="i.value"
                        :key="i.value"
                    >{{ i.label }}</Option>
                </Select>
                <!-- <div class="icon-box">
          <div v-for="item in theadOptList"
               :key="item.type"
               :class="['icon left', {active: theadType===item.type}]"
               @click="switchThead(item.type)">
            <Icon type="ios-stats" />
          </div>
                </div>-->
            </div>
        </div>
        <div v-if="type=='table'">
            <Table
                border
                class="table"
                :columns="columns"
                tooltip-theme="light"
                :loading="loading"
                :data="data"
            ></Table>
            <div class="page">
                <Page
                    :total="total"
                    @on-change="changePage"
                    :page-size="pageSize"
                    :current="current"
                    @on-page-size-change="pageSizeChange"
                    show-elevator
                    show-sizer
                    :page-size-opts = sizeOpts
                />
            </div>
        </div>
        <div v-if="type=='chart'">
            <div class="echarts" :id="id"></div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'tableBox',
    components: {
    },
    data() {
        return {
            sizeOpts:[10,20,30,40],
            theadType: 1,
            theadOptList: [
                {
                    type: 1,
                    background: 'rgb(76, 201, 233)'
                },
                {
                    type: 2,
                    background: 'rgb(76, 201, 233)'
                }
            ],
        }
    },
    props: {
        title: {
            type: String,
            default: '表格标题'
        },
        loading: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'table'
        },
        current: {
            type: Number,
            default: 1
        },
        columns: {
            type: Array,
        },
        data: {
            type: Array,
        },
        showFunctionBar: {
            type: Boolean,
            default: true
        },
        tableOption: {
            type: Array
        },
        optionD: {
            type: String,
        },
        option: {
            type: Object,
        },
        id: {
            type: String,
        },
        total: {
            type: Number,
            default: 0
        },
        pageSize: {
            type: Number,
            default: 10
        }
    },
    computed: {

    },
    mounted() {
        if (this.type == 'chart' && this.option) {
            this.initChart()
        }
       this.total > 100 ? this.sizeOpts = [10,80,100,200] : this.sizeOpts = [10,20,30,40]
    },
    methods: {
        initChart() {
            var myChart = this.$echarts.init(document.getElementById(this.id));
            //初始化数据
            myChart.setOption(this.option);
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
        pageSizeChange(pageSize) {
            this.$emit('pageSizeChange', pageSize)
        },
        changeOption(value, item) {
            this.$emit('changeOption', value, item.name)
        }
    }
}
</script>
<style lang="scss">
.table-cbox {
    width: 100%;
    padding: 20px 0;
    .title {
        color: #1562d6;
        position: relative;
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        margin-bottom: 20px;
        &::after {
            width: 4px;
            height: 20px;
            content: "";
            position: absolute;
            background: #1562d6;
            left: 0;
            top: calc(50% - 10px);
        }
    }
    .ivu-select-selection {
        max-height: 24px;
        overflow: hidden;
    }
    .top-bar {
        width: 100%;
        height: 40px;
        padding-top: 8px;
        padding-left: 24px;
        background-color: rgba(234, 234, 234, 1);
        position: relative;

        .download-table {
            width: 68px;
            height: 25px;
            background-color: rgba(241, 110, 113, 1);
            font-size: 12px;
            color: #fff;
            border-radius: 5px;
            line-height: 25px;
            text-align: center;
            cursor: pointer;
        }

        .table-opt {
            height: 100%;
            padding-top: 8px;
            position: absolute;
            top: 0;
            right: 0;
            padding-right: 15px;

            ::v-deep .ivu-select {
                font-size: 12px;

                .ivu-select-placeholder,
                .ivu-select-selected-value {
                    font-size: 12px;
                    color: #666;
                }
            }

            .icon-box {
                width: 90px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                font-size: 0;
                display: flex;

                .icon {
                    width: 45px;
                    height: 100%;
                    background-color: rgb(46, 120, 180);
                    position: relative;
                    cursor: pointer;

                    &.active {
                        background-color: #a2abbb;

                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-top: 7px solid transparent;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid #fff;
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }
    .page {
        text-align: right;
        margin-top: 20px;
    }
    .echarts {
        width: 100%;
        height: 300px;
    }
}
</style>
