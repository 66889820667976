<template>
<div class="result-box" ref="content">
    <div class="content-box" v-if="!showDetail">
        <div class="search-bar">
            <div class="left-title" @click="showDetailHandle">
                <Icon type="ios-list-box-outline" />估价报告
            </div>
            <div class="center">
                <Dropdown trigger="click" class="optionBox" @on-click="changeSearch">
                    <div class="searchOption">
                        {{ yp.cs }}
                        <Icon class="downIcon" type="ios-arrow-down"></Icon>
                    </div>
                    <DropdownMenu slot="list">
                        <DropdownItem :key="index" :name="item.csId" v-for="(item, index) in searchList">{{ item.cs }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <input type="text" v-model="searchValue" placeholder="请输入搜索内容" @focus="inputSearch" @input="inputSearch" @click.stop @keyup.enter="searchHandle" class="searchInput" />
                <Button class="searchBtn" type="primary" :loading="searchLoading" @click="searchHandle">立即搜索</Button>

                <div class="searchTipsBox" v-if="searchHaveAddressList.length && showSearchList">
                    <div class="searchTipsItem" v-for="(item, index) in searchHaveAddressList" @click.stop="chooseSearchAddress(item)" :key="index">
                        <div class="key">{{ item.xqName }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-box">
            <div class="left-top-box">
                <div class="place-info">
                    <div class="title">{{ yp.xqmc }}</div>
                    <div class="time" v-if="yp.pgsj == ''">
                        估价基准日：{{ yp.pgsj }}
                    </div>
                    <div class="time" v-else>
                        估价基准日：{{ yp.pgsj | formatDate }}
                    </div>
                    <div class="address">
                        <svg-icon class="mapIcon" iconClass="map"></svg-icon>
                        <Tooltip :content="yp.xqdz" theme="light" max-width="500">{{yp.xqdz}}</Tooltip>
                    </div>
                    <div class="price">
                        <div class="i-price">
                            <div class="p-title">平均单价</div>
                            <div class="p-num">
                                {{ yp.pjpgdj ? parseFloat(yp.pjpgdj).toFixed(0) : "--" }}元/m²
                            </div>
                        </div>
                        <div class="i-price">
                            <div class="p-title">小区估价对象总价</div>
                            <div class="p-num">
                                {{ yp.pjpgzj ? parseFloat(yp.pjpgzj / 10000).toFixed(0) : "--"}}万元
                            </div>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="item">
                            <div class="info">平均租金价格</div>
                            <div class="info">
                                {{yp.pjzjjg == "" ? "--" : parseFloat(yp.pjzjjg).toFixed(2)}}元/m²/月
                            </div>
                        </div>

                        <div class="item">
                            <div class="info">平均售租比</div>
                            <div class="info">
                                {{yp.pjszb == "" ? "--" : parseFloat(yp.pjszb).toFixed(2)}}月
                            </div>
                            <!--  <div class="info">修正后 21元/m²/月</div>-->
                        </div>
                    </div>
                </div>
                <div class="other-list">
                    <div class="other-nav-list">
                        <div class="item" :key="index" :class="activeTab == item.id ? 'active' : ''" @click="chooseTab(item)" v-for="(item, index) in tabs">
                            {{ item.name }}
                        </div>
                    </div>
                    <div v-if="activeTab == 0">
                        <div class="other-tab">
                            <div class="tipsInfo" @click="showTips">
                                <Icon type="ios-help-circle-outline" />
                            </div>

                            <span @click="activeOtherTab = 2;_yp_cjal();" :class="activeOtherTab == 2 ? 'active' : ''">成交案例</span>
                            <span @click="activeOtherTab = 1;_yp_gpal();" :class="activeOtherTab == 1 ? 'active' : ''">挂牌案例</span>
                        </div>
                        <div class="other-info-list" v-if="activeOtherTab == 1">
                            <div class="item" :key="index" v-for="(item, index) in ckalGpList">
                                <img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
                                <img v-else class="img" :src="item.ypImgUrl" />
                                <div class="title">{{ item.lpmc }}</div>
                                <div class="size">
                                    <span>{{ item.mj }}m²</span>
                                    <span>{{ item.hx }}</span>
                                </div>
                                <div class="time">挂牌时间：{{ item.gpsj }}</div>
                                <div class="priceItem sPrice">
                                    <div class="icon">单</div>
                                    <div class="price">{{ item.dj }}</div>
                                    <div class="dw">元/m²</div>
                                </div>
                                <div class="priceItem aPrice">
                                    <div class="icon">总</div>
                                    <div class="price">
                                        {{parseFloat((parseFloat(item.dj) * parseFloat(item.mj)) / 10000).toFixed(0)}}
                                    </div>
                                    <div class="dw">万元</div>
                                </div>
                            </div>
                        </div>
                        <div class="other-info-list" v-if="activeOtherTab == 2">
                            <div class="item" :key="index" v-for="(item, index) in ckalCjList">
                                <img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
                                <img v-else class="img" :src="item.ypImgUrl" />
                                <div class="title">{{ item.lpmc }}</div>
                                <div class="size">
                                    <span>{{ item.mj }}m²</span>
                                    <span>{{ item.hx }}</span>
                                </div>
                                <div class="time">成交时间：{{ item.cjsj }}</div>
                                <div class="priceItem sPrice">
                                    <div class="icon">单</div>
                                    <div class="price">{{ item.dj }}</div>
                                    <div class="dw">元/m²</div>
                                </div>
                                <div class="priceItem aPrice">
                                    <div class="icon">总</div>
                                    <div class="price">
                                        {{parseFloat((parseFloat(item.dj) * parseFloat(item.mj)) / 10000).toFixed(0)}}
                                    </div>
                                    <div class="dw">万元</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="activeTab == 1">
                        <div class="other-info-list" style="height: 575px">
                            <div class="item" :key="index" v-for="(item, index) in ckalList" @click="clickCkal(item)">
                                <img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
                                <img v-else class="img" :src="item.ypImgUrl" />
                                <div class="title">{{ item.lpmc }}</div>
                                <div class="address">
                                    <svg-icon class="mapIcon" iconClass="map"></svg-icon>
                                    <span>{{ item.juli }}米</span>
                                </div>
                                <div class="priceItem sPrice">
                                    <div class="icon">均</div>
                                    <div class="price">{{ item.dj?parseFloat(item.dj).toFixed(0):'--' }}</div>
                                    <div class="dw">元/m²</div>
                                </div>
                                <!--  <div class="priceItem aPrice">
                    <div class="icon">总</div>
                    <div class="price">{{parseFloat((parseFloat(item.zj)*parseFloat(item.mj))/10000).toFixed(2)}}</div>
                    <div class="dw">万元</div>
                  </div>-->
                            </div>
                        </div>
                    </div>
                    <div v-else-if="activeTab == 2" :style="{ 'padding-top': '30px' }">
                        <Tabs v-model="tab2" @on-click="chooseTab2">
                            <TabPane label="交通" name="tab2">
                                <div class="other-info-list" style="height: 492px; overflow: auto">
                                    <div class="itemT" :key="index" v-for="(item, index) in tabData['交通']" @click="clickPoint(item)">
                                        <div class="title">{{ item.title }}</div>
                                        <div class="address">
                                            <svg-icon class="mapIcon" iconClass="map"></svg-icon>
                                            <span>{{ item.juli }}米</span>
                                        </div>
                                        <div v-for="(type, index) in item.tags" v-html="type" :key="type" :class="['type', `type-${index}`]"></div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane label="教育" name="tab3">
                                <div class="other-info-list" style="height: 492px; overflow: auto">
                                    <div class="itemT" :key="index" v-for="(item, index) in tabData['教育']" @click="clickPoint(item)">
                                        <div class="title">{{ item.title }}</div>
                                        <div class="address">
                                            <svg-icon class="mapIcon" iconClass="map"></svg-icon>
                                            <span>{{ item.juli }}米</span>
                                        </div>
                                        <div v-for="(type, index) in item.tags" v-html="type" :key="type" :class="['type', `type-${index}`]"></div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane label="医疗" name="tab4">
                                <div class="other-info-list" style="height: 492px; overflow: auto">
                                    <div class="itemT" :key="index" v-for="(item, index) in tabData['医疗']" @click="clickPoint(item)">
                                        <div class="title">{{ item.title }}</div>
                                        <div class="address">
                                            <svg-icon class="mapIcon" iconClass="map"></svg-icon>
                                            <span>{{ item.juli }}米</span>
                                        </div>
                                        <div v-for="(type, index) in item.tags" v-html="type" :key="type" :class="['type', `type-${index}`]"></div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane label="商业" name="tab5">
                                <div class="other-info-list" style="height: 492px; overflow: auto">
                                    <div class="itemT" :key="index" v-for="(item, index) in tabData['商业']" @click="clickPoint(item)">
                                        <div class="title">{{ item.title }}</div>
                                        <div class="address">
                                            <svg-icon class="mapIcon" iconClass="map"></svg-icon>
                                            <span>{{ item.juli }}米</span>
                                        </div>
                                        <div v-for="(type, index) in item.tags" v-html="type" :key="type" :class="['type', `type-${index}`]"></div>
                                    </div>
                                </div>
                            </TabPane>

                            <TabPane label="特殊" name="tab1">
                                <div class="other-info-list" style="height: 492px; overflow: auto">
                                    <div class="itemT" :key="index" v-for="(item, index) in tabData['厌恶']" @click="clickPoint(item)">
                                        <div class="title">{{ item.title }}</div>
                                        <div class="address">
                                            <svg-icon class="mapIcon" iconClass="map"></svg-icon>
                                            <span>{{ item.juli }}米</span>
                                        </div>
                                        <div v-for="(type, index) in item.tags" v-html="type" :key="type" :class="['type', `type-${index}`]"></div>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                        <div class="fanwei">
                            <Input v-model="searchWidth" size="small" placeholder="单位：公里" maxlength="4" search enter-button style="width: 300px" @on-search="searchByWidth">
                            <span slot="prepend">范围：（0-5）公里</span>
                            </Input>
                        </div>
                    </div>

                    <div class="tabInfo" v-else-if="activeTab == 3">
                        <div class="info">
                            <div class="line" v-show="xqxq.lpmc">
                                <div class="name">小区名称</div>
                                <div class="data norwap">
                                    <Tooltip theme="light" max-width="900" :content="xqxq.lpmc" word-wrap :transfer="true" placement="bottom">{{ xqxq.lpmc }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.qx">
                                <div class="name">行政区</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :content="xqxq.qx" word-wrap :transfer="true" placement="bottom">{{ xqxq.qx }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.sq">
                                <div class="name">板块/商圈</div>
                                <div class="data norwap">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.sq" word-wrap placement="bottom">{{ xqxq.sq }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.sldz">
                                <div class="name">小区地址</div>

                                <div class="data norwap">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.sldz" word-wrap placement="bottom">{{ xqxq.sldz }}</Tooltip>
                                </div>
                            </div>

                            <div class="line" v-show="xqxq.wylb">
                                <div class="name">物业类型</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.wylb" word-wrap placement="bottom">{{ xqxq.wylb }}</Tooltip>
                                </div>
                            </div>

                            <div class="line" v-show="xqxq.cqnx">
                                <div class="name">产权年限</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.cqnx" word-wrap placement="bottom">{{ xqxq.cqnx }}</Tooltip>
                                </div>
                            </div>

                            <div class="line" v-show="xqxq.kfs">
                                <div class="name">开发商</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.kfs" word-wrap placement="bottom">{{ xqxq.kfs }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.jzlb">
                                <div class="name">建筑类型</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jzlb" word-wrap placement="bottom">{{ xqxq.jzlb }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.jzjg">
                                <div class="name">建筑结构</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jzjg" word-wrap placement="bottom">{{ xqxq.jzjg }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.zxzk">
                                <div class="name">装修状况</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.zxzk" word-wrap placement="bottom">{{ xqxq.zxzk }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.dtpz">
                                <div class="name">电梯配置</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.dtpz" word-wrap placement="bottom">{{ xqxq.dtpz }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.jznd">
                                <div class="name">建筑年代</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jznd" word-wrap placement="bottom">{{ xqxq.jznd }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.zthx">
                                <div class="name">主力户型</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.zthx" word-wrap placement="bottom">{{ xqxq.zthx }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.kpsj">
                                <div class="name">开盘时间</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.kpsj" word-wrap placement="bottom">{{ xqxq.kpsj }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.jfsj">
                                <div class="name">交房时间</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jfsj" word-wrap placement="bottom">{{ xqxq.jfsj }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.zdmj">
                                <div class="name">占地面积</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.zdmj" word-wrap placement="bottom">{{ xqxq.zdmj }}m²</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.jzmj">
                                <div class="name">建筑面积</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jzmj" word-wrap placement="bottom">{{ xqxq.jzmj }}m²</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.zhs">
                                <div class="name">总户数</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.zhs" word-wrap placement="bottom">{{ xqxq.zhs }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.ldzs">
                                <div class="name">楼栋总数</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.ldzs" word-wrap placement="bottom">{{ xqxq.ldzs }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.lhl">
                                <div class="name">绿化率</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.lhl" word-wrap placement="bottom">{{ xqxq.lhl }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.rjl">
                                <div class="name">容积率</div>
                                <div class="data">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.rjl" word-wrap placement="bottom">{{ xqxq.rjl }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.tcw">
                                <div class="name">停车位数</div>
                                <div class="data norwap">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.tcw" word-wrap placement="bottom">{{ xqxq.tcw }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.tcwpb">
                                <div class="name">停车位配比</div>
                                <div class="data norwap">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.tcwpb" word-wrap placement="bottom">{{ xqxq.tcwpb }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.wygs">
                                <div class="name">物业公司</div>
                                <div class="data norwap">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.wygs" word-wrap placement="bottom">{{ xqxq.wygs }}</Tooltip>
                                </div>
                            </div>
                            <div class="line" v-show="xqxq.wyf">
                                <div class="name">物业费</div>
                                <div class="data norwap">
                                    <Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.wyf" word-wrap placement="bottom">{{ xqxq.wyf }}元/m²/月</Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tabInfo" v-else-if="activeTab == 4">
                        <div id="chart9"></div>
                    </div>
                </div>
            </div>
            <div class="right-top-box">
                <div class="map" id="map"></div>
                <div class="map-table">
                    <div class="top-tab">
                        <div class="item" @click="changeMapTab(1)" :class="{ active: mapTab == 1 }">
                            估价历史
                        </div>
                        <!-- <div class="item"
                   @click="changeMapTab(2)"
                   :class="{active:mapTab==2}">业内估价参考</div>
              <div class="item"
                   @click="changeMapTab(3)"
              :class="{active:mapTab==3}">估价对象详情</div>-->
                    </div>
                    <div class="tabInfo" v-if="mapTab == 3">
                        <div class="info">
                            <div class="line">
                                <div class="name">所属区域</div>
                                <div class="data">{{ ypXq.cs }}{{ ypXq.xzq }}</div>
                            </div>
                            <div class="line">
                                <div class="name">证载地址</div>
                                <div class="data">{{ ypXq.zzdz }}</div>
                            </div>
                            <div class="line">
                                <div class="name">小区名称</div>
                                <div class="data" @click="toRegionResult">
                                    {{ ypXq.xqmc }}
                                </div>
                            </div>
                            <div class="line">
                                <div class="name">楼栋</div>
                                <div class="data">{{ ypXq.ld }}栋</div>
                            </div>
                            <div class="line">
                                <div class="name">单元</div>
                                <div class="data">{{ ypXq.dy }}单元</div>
                            </div>
                            <div class="line">
                                <div class="name">房号</div>
                                <div class="data">{{ ypXq.fh }}室</div>
                            </div>

                            <div class="line">
                                <div class="name">建筑面积</div>
                                <div class="data">{{ ypXq.jzmj }}㎡</div>
                            </div>
                            <div class="line">
                                <div class="name">所在楼层/总楼层</div>
                                <div class="data">{{ ypXq.szlc }}/{{ ypXq.zlc }}</div>
                            </div>
                            <div class="line">
                                <div class="name">房屋户型</div>
                                <div class="data">{{ ypXq.fwhx }}</div>
                            </div>

                            <div class="line">
                                <div class="name">朝向</div>
                                <div class="data">{{ ypXq.cx }}</div>
                            </div>
                            <div class="line">
                                <div class="name">物业类型</div>
                                <div class="data">{{ ypXq.wylx }}</div>
                            </div>
                            <div class="line">
                                <div class="name">建筑年代</div>
                                <div class="data">{{ ypXq.jcnd }}年</div>
                            </div>
                            <div class="line">
                                <div class="name">户型结构</div>
                                <div class="data">{{ ypXq.zxzk }}</div>
                            </div>
                            <div class="line">
                                <div class="name">建筑结构</div>
                                <div class="data">{{ ypXq.jzjg }}</div>
                            </div>
                            <div class="line">
                                <div class="name">建筑类型</div>
                                <div class="data">{{ ypXq.jzlx }}</div>
                            </div>
                        </div>
                    </div>
                    <Table border v-else-if="mapTab == 1" class="table" tooltip-theme="light" height="376" :columns="ypLs.key" :data="ypLs.data"></Table>
                    <!--   <div class="more"
                 v-if="mapTab==1"
            @click="showDetailHandle">更多 >></div>-->
                    <Table border v-else-if="mapTab == 2" class="table" tooltip-theme="light" height="376" :columns="ynpg.key" :data="ynpg.data"></Table>
                </div>
            </div>
        </div>
        <div class="bottom-table">
                        <tableBox title="本小区估价对象列表" :tableOption="tableOption1" :showFunctionBar="false" :total="xqyp.total" @changePage="changeXqyp" :columns="xqyp.key" :current="params.current" @pageSizeChange="pageSizeChangeXqyp" :data="xqyp.data"></tableBox>

            // <div class="tabBox">
            //     <Tabs v-model="tab">
            //         <TabPane label="本小区估价对象列表" name="tab1">
            //             <tableBox title="本小区估价对象列表" :tableOption="tableOption1" :showFunctionBar="false" :total="xqyp.total" @changePage="changeXqyp" :columns="xqyp.key" :current="params.current" @pageSizeChange="pageSizeChangeXqyp" :data="xqyp.data"></tableBox>
            //         </TabPane>
            //         <TabPane label="土地市场" name="tab2">
            //             <tableBox title="土地市场" :tableOption="tableOption2" :showFunctionBar="true" :columns="td.key" :total="td.total" @changeOption="changeTdOption" @changePage="changeTd" :loading="tdLoading" :current="params.current" @pageSizeChange="pageSizeChangeTd" :data="td.data"></tableBox>
            //             <!-- <tableBox title='土地市场'
            //             type="chart"
            //             id="echarts"
            //             :option="option"
            //             :tableOption="[]"
            //   :showFunctionBar="true"></tableBox>-->
            //         </TabPane>
            //         <TabPane label="商品房市场" name="tab3">
            //             <tableBox title="商品房市场" :tableOption="tableOption3" :showFunctionBar="true" :columns="spf.key" :total="spf.total" @changePage="changeSpf" :loading="spfLoding" :current="params.current" @changeOption="changeSpfOption" @pageSizeChange="pageSizeChangeSpf" :data="spf.data"></tableBox>
            //             <!-- <tableBox title='商品房市场'
            //             type="chart"
            //             id="echarts2"
            //             :option="option2"
            //             :tableOption="[]"
            //   :showFunctionBar="true"></tableBox>-->
            //         </TabPane>
            //         <TabPane label="司法拍卖" name="tab4" disabled>
            //             <tableBox title="司法拍卖" :tableOption="[]" :showFunctionBar="true" :columns="sfpm.key" :total="sfpm.total" :current="params.current" @changePage="changeSfpm" @pageSizeChange="pageSizeChangeSfpm" :data="sfpm.data"></tableBox>
            //         </TabPane>
            //         <TabPane label="产权交易所/交易所" name="tab5" disabled>
            //             <tableBox title="产权交易所/第三所" :tableOption="[]" :showFunctionBar="true" :columns="cqjys.key" :total="cqjys.total" :current="params.current" @changePage="changeCqjys" @pageSizeChange="pageSizeChangeCqjys" :data="cqjys.data"></tableBox>
            //         </TabPane>
            //     </Tabs>
            // </div>
        </div>
    </div>
    <div class="detail" v-else>
        <Button @click="hideDetailHandle" class="backBtn">返回</Button>
        <Button type="primary" class="downloadBtn" @click="toImage">下载</Button>

        <div class="detailBox" ref="detail">
            <div class="title">{{ yp.xqmc }}估价报告</div>
            <div class="sData">
                <span>估价委托方：{{ yp.wtf }}</span>
                <span>估价方：{{ yp.gjf }}</span>
                <span>估价基准日：{{ yp.pgsj | formatDate }}</span>
            </div>
            <div class="grayLine"></div>
            <div class="detailItem">
                <div class="itemTitle">一、估价对象及估价结果</div>
                <div class="itemInfo">
                    <span style="font-weight: bold; margin-right: 10px">[估价对象]</span>
                    {{ yp.xqmc }}
                </div>
                <div class="itemInfo">
                    <span style="font-weight: bold; margin-right: 10px">[所属省市]</span>
                    {{ yp.cs }}{{ yp.xzq }}
                </div>
                <div class="itemInfo" style="margin-bottom: 20px">
                    <span style="font-weight: bold; margin-right: 10px">[项目地址]</span>
                    {{ yp.xqdz }}
                </div>
                <div class="sTitle">估价结果</div>
                <div class="resultBox">
                    <div class="resultItem">
                        <div class="info">平均单价</div>
                        <div class="info">
                            <h4>{{ yp.pjpgdj }}元/m²</h4>
                        </div>
                    </div>
                    <div class="resultItem">
                        <div class="info">总价</div>
                        <div class="info">
                            <h4>{{ parseFloat(yp.pjpgzj / 10000) }}万元</h4>
                        </div>
                    </div>
                    <div class="resultItem">
                        <div class="info">平均租金价格</div>
                        <div class="info">
                            <h4>{{ yp.pjzjjg == "" ? "--" : yp.pjzjjg }}元/m²/月</h4>
                        </div>
                    </div>

                    <div class="resultItem">
                        <div class="info">平均售租比</div>
                        <div class="info">
                            <h4>{{ yp.pjszb == "" ? "--" : yp.pjszb }}月</h4>
                        </div>
                    </div>
                    <div class="resultItem danger">
                        <div class="info">风险预警数量</div>
                        <div class="info">
                            <h4>{{ yp.yjts }}套</h4>
                        </div>
                    </div>
                    <div class="resultItem danger">
                        <div class="info">风险押品总值</div>
                        <div class="info">
                            <h4>{{ yp.ypze }}万元</h4>
                        </div>
                    </div>
                </div>
                <div class="sTitle">具体估价结果</div>
                <Table border class="table" tooltip-theme="light" :columns="ypLs.key" :data="ypLs.data"></Table>
                <div class="sTitle">估价对象详情</div>
                <Table border class="table" tooltip-theme="light" :columns="xqyp.key2" :data="xqyp.data"></Table>
                <div class="sTitle">地理位置</div>
                <div class="map" id="map2"></div>
            </div>
            <div class="detailItem">
                <div class="itemTitle">二、参考案例</div>
                <Table :columns="referenceColumns" :data="ckalGpList" border></Table>
                <Table :columns="referenceColumns2" :data="ckalCjList" border></Table>
            </div>

            <div class="detailItem">
                <div class="itemTitle">三、所属小区</div>
                <div class="contentInfo">
                    <div class="left">
                        <div class="contentLine">
                            <span class="contentTitle">小区名称</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.lpmc" word-wrap :transfer="true" placement="bottom">{{ xqxq.lpmc }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">销售状态</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.xszt" word-wrap :transfer="true" placement="bottom">{{ xqxq.xszt }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">新房参考价</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.xfckj" word-wrap :transfer="true" placement="bottom">{{ xqxq.xfckj }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">小区地址</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.sldz" word-wrap :transfer="true" placement="bottom">{{ xqxq.sldz }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">区县</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.qx" word-wrap :transfer="true" placement="bottom">{{ xqxq.qx }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">商圈/板块</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.sq" word-wrap :transfer="true" placement="bottom">{{ xqxq.sq }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">开发商</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.kfs" word-wrap :transfer="true" placement="bottom">{{ xqxq.kfs }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">物业类型</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.wylb" word-wrap :transfer="true" placement="bottom">{{ xqxq.wylb }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">产权年限</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.cqnx" word-wrap :transfer="true" placement="bottom">{{ xqxq.cqnx }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">开盘时间</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.kpsj" word-wrap :transfer="true" placement="bottom">{{ xqxq.kpsj }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">交房时间</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.jfsj" word-wrap :transfer="true" placement="bottom">{{ xqxq.jfsj }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">建筑类型</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.jzlb" word-wrap :transfer="true" placement="bottom">{{ xqxq.jzlb }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">绿化率</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.lhl" word-wrap :transfer="true" placement="bottom">{{ xqxq.lhl }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">占地面积</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.zdmj" word-wrap :transfer="true" placement="bottom">{{ xqxq.zdmj }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">建筑面积</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.jzmj" word-wrap :transfer="true" placement="bottom">{{ xqxq.jzmj }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">容积率</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.rjl" word-wrap :transfer="true" placement="bottom">{{ xqxq.rjl }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">楼栋总数</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.ldzs" word-wrap :transfer="true" placement="bottom">{{ xqxq.ldzs }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">总户数</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.zhs" word-wrap :transfer="true" placement="bottom">{{ xqxq.zhs }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">装修情况</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.zxqk" word-wrap :transfer="true" placement="bottom">{{ xqxq.zxqk }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">户型</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.hxwz" word-wrap :transfer="true" placement="bottom">{{ xqxq.hxwz }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">车位数</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.cws" word-wrap :transfer="true" placement="bottom">{{ xqxq.cws }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">车位配比</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.cwpp" word-wrap :transfer="true" placement="bottom">{{ xqxq.cwpp }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">物业公司</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.wygs" word-wrap :transfer="true" placement="bottom">{{ xqxq.wygs }}</Tooltip>
                            </span>
                        </div>
                        <div class="contentLine">
                            <span class="contentTitle">物业费</span>
                            <span class="content norwap">
                                <Tooltip theme="light" max-width="900" :content="xqxq.wyf" word-wrap :transfer="true" placement="bottom">{{ xqxq.wyf }}</Tooltip>
                            </span>
                        </div>
                    </div>
                    <div class="right">
                        <div class="sTitle">价格走势</div>
                        <div id="chart2"></div>
                    </div>
                </div>
            </div>

            <div class="detailItem">
                <div class="itemTitle">四、周边</div>
                <div class="sTitle">周边小区</div>
                <div class="hList">
                    <div class="item" :key="index" v-for="(item, index) in ckalList">
                        <img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
                        <img v-else class="img" :src="item.ypImgUrl" />
                        <div class="title">{{ item.lpmc }}</div>
                        <div class="address">
                            <svg-icon class="mapIcon" iconClass="map"></svg-icon>
                            <span>{{ item.juli }}米</span>
                        </div>
                        <!-- <div class="priceItem sPrice">
              <div class="icon">单</div>
              <div class="price">{{item.dj||0.00}}</div>
              <div class="dw">元/m²</div>
              </div>-->
                        <div class="priceItem aPrice" style="top: 14px">
                            <div class="icon">均</div>
                            <div class="price">
                                {{ item.dj ? parseFloat(item.dj).toFixed(0) : "--" }}
                            </div>
                            <div class="dw">元/m²</div>
                        </div>
                    </div>
                </div>
                <div class="sTitle">周边配套</div>
                <div class="hLine">
                    <div class="htitle">交通</div>
                    <div class="hitemList">
                        <div class="item" :key="index" v-for="(item, index) in tabData['交通']" v-show="index < 20">
                            <span class="name">{{ item.title }}</span>
                            <span class="length">[{{ item.juli }}米]</span>
                            <span class="type" v-if="item.tag" v-html="item.tag"></span>
                        </div>
                    </div>
                </div>
                <div class="hLine">
                    <div class="htitle">教育</div>
                    <div class="hitemList">
                        <div class="item" :key="index" v-for="(item, index) in tabData['教育']" v-show="index < 20">
                            <span class="name">{{ item.title }}</span>
                            <span class="length">[{{ item.juli }}米]</span>
                            <span class="type" v-if="item.tag" v-html="item.tag"></span>
                        </div>
                    </div>
                </div>
                <div class="hLine">
                    <div class="htitle">医疗</div>
                    <div class="hitemList">
                        <div class="item" :key="index" v-for="(item, index) in tabData['医疗']" v-show="index < 20">
                            <span class="name">{{ item.title }}</span>
                            <span class="length">[{{ item.juli }}米]</span>
                            <span class="type" v-if="item.tag" v-html="item.tag"></span>
                        </div>
                    </div>
                </div>
                <div class="hLine">
                    <div class="htitle">商业</div>
                    <div class="hitemList">
                        <div class="item" :key="index" v-for="(item, index) in tabData['商业']" v-show="index < 20">
                            <span class="name">{{ item.title }}</span>
                            <span class="length">[{{ item.juli }}米]</span>
                            <span class="type" v-if="item.tag" v-html="item.tag"></span>
                        </div>
                    </div>
                </div>
                <div class="hLine">
                    <div class="htitle">特殊</div>
                    <div class="hitemList">
                        <div class="item" :key="index" v-for="(item, index) in tabData['厌恶']" v-show="index < 20">
                            <span class="name">{{ item.title }}</span>
                            <span class="length">[{{ item.juli }}米]</span>
                            <span class="type" v-if="item.tag" v-html="item.tag"></span>
                        </div>
                    </div>
                </div>
            </div>
            // <div class="detailItem">
            //     <div class="itemTitle">五1、土地市场</div>
            //     <Table border tooltip-theme="light" :columns="td.key" :data="td.data"></Table>
            //     <div class="chartItem" v-show="false" id="chart5"></div>
            // </div>
            // <div class="detailItem">
            //     <div class="itemTitle">六、商品房市场</div>
            //     <Table border tooltip-theme="light" :columns="spf.key" :data="spf.data"></Table>
            //     <div class="chartItem" v-show="false" id="chart6"></div>
            // </div>
            <div class="detailItem" v-show="false">
                <div class="itemTitle">七、司法拍卖</div>
                <Table border tooltip-theme="light" :columns="sfpm.key" :data="sfpm.data"></Table>
                <div class="chartItem" v-show="false" id="chart7"></div>
            </div>
            <div class="detailItem" v-show="false">
                <div class="itemTitle">八、产权交易所/第三方交易所</div>
                <Table border tooltip-theme="light" :columns="cqjys.key" :data="cqjys.data"></Table>
                <div class="chartItem" v-show="false" id="chart8"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import tableBox from "@/components/tableBox";
import BMap from "BMap";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import BMapLib from "BMapLib";
import {
    xiaoqu,
    rwlb,
    yp_xqpg,
    yp_xq_cjal,
    xqyp_xq_list,
    yp_xq_td,
    yp_wylx,
    yp_tdtj,
    yp_xq_zxq,
    yp_xq_spf,
    yp_xq_sfpm,
    yp_xq_cqjys,
    yp_xq,
    yp_xq_history,
    yp_ynpg,
    recordByTaskId,
    cs,
    yp_xq_gpal,
    yp_xq_zbxq,
    yp_xq_xqxq,
    yp_xq_jgzs,
} from "@/api/pledge";
import {
    getLandUse2
} from "@/api/public";
import {
    formatDate
} from "@/utils";
export default {
    name: "result",
    components: {
        tableBox,
    },
    data() {
        return {
            searchWidth: "1",
            searchItem: null,
            params: {
                current: 1,
                size: 10,
                cs: "",
                xqId: "",
                csId: "",
                xqmc: "",
            },
            // 地图标注icon图片
            liveIcon: require("@/assets/image/map/live.png"),
            liveIconActive: require("@/assets/image/map/live_active.png"),
            closeIcon: require("@/assets/image/map/close.png"),
            overlayCloseIcon: require("@/assets/image/map/overLay_close.svg"),
            referenceData: [],
            referenceColumns: [{
                    title: "序号",
                    align: "center",
                    type: "index",
                },
                {
                    title: "案例",
                    align: "center",
                    key: "al",
                    render: (h, params) => {
                        return h("div", "挂牌案例");
                    },
                },
                {
                    title: "小区名称",
                    align: "center",
                    key: "lpmc",
                },
                {
                    title: "挂牌时间",
                    align: "center",
                    key: "gpsj",
                },
                {
                    title: "建筑面积（m²）",
                    align: "center",
                    key: "mj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "建筑面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "房屋户型",
                    align: "center",
                    key: "hx",
                },
                {
                    title: "挂牌总价（万元）",
                    align: "center",
                    key: "dj",
                    render: (h, params) => {
                        return h(
                            "div",
                            (params.row.mj * params.row.dj) / 10000 ?
                            ((params.row.mj * params.row.dj) / 10000).toFixed(0) :
                            ""
                        );
                    },
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "挂牌总价"), h("div", "（万元）")]);
                    },
                },
            ],
            referenceColumns2: [{
                    title: "序号",
                    align: "center",
                    type: "index",
                },
                {
                    title: "案例",
                    align: "center",
                    key: "al",
                    render: (h, params) => {
                        return h("div", "成交案例");
                    },
                },
                {
                    title: "小区名称",
                    align: "center",
                    key: "lpmc",
                },
                {
                    title: "成交时间",
                    align: "center",
                    key: "cjsj",
                },
                {
                    title: "建筑面积（m²）",
                    align: "center",
                    key: "mj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "建筑面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "房屋户型",
                    align: "center",
                    key: "hx",
                },
                {
                    title: "成交总价（万元）",
                    align: "center",
                    key: "dj",
                    render: (h, params) => {
                        return h(
                            "div",
                            (params.row.mj * params.row.dj) / 10000 ?
                            ((params.row.mj * params.row.dj) / 10000).toFixed(0) :
                            ""
                        );
                    },
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "成交总价"), h("div", "（万元）")]);
                    },
                },
            ],

            tabs: [{
                    id: 0,
                    name: "参考案例",
                },
                {
                    id: 1,
                    name: "周边小区",
                },
                {
                    id: 2,
                    name: "周边配套",
                },
                {
                    id: 3,
                    name: "小区详情",
                },
                {
                    id: 4,
                    name: "价格走势",
                },
            ],
            activeTab: 0,
            mapData: {
                zoom: 12,
                center: {
                    lng: 116.46,
                    lat: 39.92,
                },
            },
            activeOtherTab: 2,
            tab: "",
            tab2: "tab2",
            tableOption1: [{
                name: "格式调整",
                optionList: [],
            }, ],
            tableOption2: [{
                    name: "土地用途",
                    multiple: true,
                    value: [],
                    optionList: [],
                },

                {
                    name: "周期",
                    optionList: [{
                            value: 1,
                            label: "月",
                        },
                        {
                            value: 3,
                            label: "年",
                        },
                    ],
                },
            ],
            tableOption3: [{
                    name: "物业类型",
                    multiple: true,
                    optionList: [],
                },
                {
                    name: "行政区",
                    multiple: true,
                    optionList: [],
                },
                {
                    name: "周期",
                    value: "月",
                    optionList: [{
                            value: 1,
                            label: "月",
                        },
                        {
                            value: 3,
                            label: "年",
                        },
                    ],
                },
            ],
            mapTab: 1,
            map: null,
            option: {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                legend: {
                    top: "14px",
                    left: "40px",
                    itemWidth: 12,
                    itemHeight: 12,
                    data: ["面积", "成交宗数", "楼面值"],
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "value",
                    axisLabel: {
                        textStyle: {
                            color: "#666",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#D6D7D9",
                        },
                    },
                },
                yAxis: {
                    axisLabel: {
                        textStyle: {
                            color: "#666",
                        },
                    },
                    type: "category",
                    data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#D6D7D9",
                        },
                    },
                },
                color: ["#5C89FF", "#7C6AF2", "#C95FF2"],
                series: [{
                        name: "面积",
                        type: "bar",
                        stack: "总量",
                        label: {
                            normal: {
                                show: true,
                                position: "insideRight",
                            },
                        },
                        data: [320, 302, 301, 334, 390, 330, 320],
                    },
                    {
                        name: "成交宗数",
                        type: "bar",
                        stack: "总量",
                        label: {
                            normal: {
                                show: true,
                                position: "insideRight",
                            },
                        },
                        data: [120, 132, 101, 134, 90, 230, 210],
                    },
                    {
                        name: "楼面值",
                        type: "bar",
                        stack: "总量",
                        label: {
                            normal: {
                                show: true,
                                position: "insideRight",
                            },
                        },
                        data: [220, 182, 191, 234, 290, 330, 310],
                    },
                ],
            },
            showDetail: false,
            option2: {
                title: {
                    text: "房地产开发投资",
                    top: "25px",
                    left: "center",
                    textStyle: {
                        color: "#666",
                        fontSize: 12,
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        label: {
                            show: true,
                        },
                    },
                    position: ["25%", "25%"],
                    formatter: (params) => {
                        let tips = "";
                        params.forEach((item, index) => {
                            var unit = "亿元";
                            tips += item.seriesName + "：" + item.value + unit + "<br/>";
                        });
                        return tips;
                    },
                },
                graphic: {
                    type: "text",
                    z: 100,
                    left: "10%",
                    top: "52px",
                    style: {
                        fill: "#666",
                        fontSize: 12,
                        text: "亿元",
                    },
                },
                color: ["#5B8FF9", "#5AD8A6", "#5D7092"],
                legend: {
                    top: "14px",
                    left: "40px",
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 5,
                    textStyle: {
                        color: "#666",
                        fontSize: 12,
                    },
                    data: ["住宅", "办公", "商业", "其他"],
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                yAxis: [{
                    // name: "亿",
                    type: "value",
                    nameTextStyle: {
                        color: "#666",
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        lineStyle: {
                            type: "dashed",
                            color: "#D6D7D9",
                        },
                    },
                    axisLabel: {
                        inside: false,
                        textStyle: {
                            color: "#666",
                            fontSize: 12,
                        },
                        formatter: "{value} ",
                    },
                    axisLine: {
                        symbol: ["none", "arrow"],
                        symbolSize: [5, 10],
                        lineStyle: {
                            color: "#D6D7D9",
                            fontSize: 12,
                        },
                    },
                }, ],
                xAxis: [{
                    type: "category",
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#D6D7D9",
                        },
                    },
                    axisLabel: {
                        color: "#666",
                        fontSize: 12,
                        formatter: "{value} ",
                    },
                    data: ["住宅", "办公", "商业", "其他"],
                }, ],
                series: [{
                        name: "住宅",
                        type: "bar",
                        stack: "总量",
                        barWidth: "46px",
                        data: [320, 302, 301, 334, 390, 330, 320],
                    },
                    {
                        name: "办公",
                        type: "bar",
                        stack: "总量",
                        data: [320, 302, 301, 334, 390, 330, 320],
                    },
                    {
                        name: "商业",
                        type: "bar",
                        stack: "总量",
                        data: [320, 302, 301, 334, 390, 330, 320],
                    },
                    {
                        name: "其他",
                        type: "bar",
                        stack: "总量",
                        data: [320, 302, 301, 334, 390, 330, 320],
                    },
                ],
            },
            option3: {
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    top: "14px",
                    left: "0px",
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 5,
                    textStyle: {
                        color: "#666",
                        fontSize: 12,
                    },
                    data: ["金碧世纪花园", "黄埔", "广州"],
                },
                color: ["#5AD8A6", "#5B8FF9", "#5D7092"],
                grid: {
                    left: "0%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
                },
                yAxis: {
                    type: "value",
                },
                series: [{
                        name: "金碧世纪花园",
                        type: "line",
                        data: [],
                        color: "#ff1e10",
                        lineStyle: {
                            color: "#ff1e10", //改变折线颜色
                        },
                    },
                    {
                        name: "黄埔",
                        type: "line",
                        color: "#1a9bff",
                        lineStyle: {
                            color: "#1a9bff", //改变折线颜色
                        },
                        data: [],
                    },
                    {
                        name: "广州",
                        type: "line",
                        data: [],
                        color: "#1aad19",
                        lineStyle: {
                            color: "#1aad19", //改变折线颜色
                        },
                    },
                ],
            },
            landColumns: [{
                    title: "序号",
                    width: 150,
                    align: "center",
                    key: "xh",
                },
                {
                    title: "各类型土地出让宗数",
                    width: 150,
                    align: "center",
                    key: "zs",
                },
                {
                    title: "出让面积（m²）",
                    width: 150,
                    align: "center",
                    key: "crmj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "出让面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "成交宗数",
                    width: 150,
                    align: "center",
                    key: "cjzs",
                },
                {
                    title: "成交面积（m²）",
                    width: 150,
                    align: "center",
                    key: "cjmj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "成交面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "成交建面（m²）",
                    width: 150,
                    align: "center",
                    key: "cjmj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "成交建面"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "楼面价（元/m²）",
                    width: 150,
                    align: "center",
                    key: "lmj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "楼面价"), h("div", "（元/m²）")]);
                    },
                },
                {
                    title: "起始价（元/m²）",
                    width: 150,
                    align: "center",
                    key: "qsj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "起始价"), h("div", "（元/m²）")]);
                    },
                },
                {
                    title: "成交价（元/m²）",
                    width: 150,
                    align: "center",
                    key: "cjj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "成交价"), h("div", "（元/m²）")]);
                    },
                },
                {
                    title: "溢价率",
                    width: 150,
                    align: "center",
                    key: "yjl",
                },
                {
                    title: "同比",
                    width: 150,
                    align: "center",
                    key: "tb",
                },
                {
                    title: "环比",
                    align: "center",
                    key: "hb",
                },
            ],
            landData: [],
            goodsColumns: [{
                    title: "时间",
                    width: 150,
                    align: "center",
                    key: "sj",
                },
                {
                    title: "地区",
                    width: 150,
                    align: "center",
                    key: "dq",
                },
                {
                    title: "物业类型",
                    width: 150,
                    align: "center",
                    key: "wylx",
                },
                {
                    title: "成交套数",
                    width: 150,
                    align: "center",
                    key: "cjts",
                },
                {
                    title: "成交面积（m²）",
                    width: 150,
                    align: "center",
                    key: "cjmj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "成交面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "成交均价（万元）",
                    width: 150,
                    align: "center",
                    key: "cjjj",
                    render: (h, params) => {
                        return h(
                            "div",
                            params.row.cjjj == "" ? "" : params.row.cjjj / 10000
                        );
                    },
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "成交均价"), h("div", "（万元）")]);
                    },
                },
                {
                    title: "成交金额（万元）",
                    width: 150,
                    align: "center",
                    key: "cjje",
                    render: (h, params) => {
                        return h(
                            "div",
                            params.row.cjje == "" ? "" : params.row.cjje / 10000
                        );
                    },
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "成交金额"), h("div", "（万元）")]);
                    },
                },
                {
                    title: "供应套数",
                    width: 150,
                    align: "center",
                    key: "gyts",
                },
                {
                    title: "供应面积（m²）",
                    width: 150,
                    align: "center",
                    key: "gymj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "供应面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "库存套数",
                    width: 150,
                    align: "center",
                    key: "kcts",
                },
                {
                    title: "库存面积（m²）",
                    width: 150,
                    align: "center",
                    key: "kcmj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "库存面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "供求比",
                    width: 150,
                    align: "center",
                    key: "gqb",
                },
                {
                    title: "去化周期",
                    align: "center",
                    key: "qhzq",
                },
            ],
            goodsData: [],
            judicialColumns: [{
                    title: "楼盘名称",
                    width: 150,
                    align: "center",
                    key: "lpmc",
                },
                {
                    title: "成交时间",
                    width: 150,
                    align: "center",
                    key: "cjsj",
                },
                {
                    title: "面积（m²）",
                    width: 150,
                    align: "center",
                    key: "mj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "居室类型",
                    width: 150,
                    align: "center",
                    key: "jslx",
                },
                {
                    title: "单价（元/m²）",
                    width: 150,
                    align: "center",
                    key: "dz",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "单价"), h("div", "（元/m²）")]);
                    },
                },
                {
                    title: "总价（万元）",
                    align: "center",
                    key: "zj",
                    render: (h, params) => {
                        return h("div", params.row.zj == "" ? "" : params.row.zj / 10000);
                    },
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "总价"), h("div", "（万元）")]);
                    },
                },
            ],
            judicialData: [],
            powerColumns: [{
                    title: "楼盘名称",
                    width: 150,
                    align: "center",
                    key: "pjmc",
                },
                {
                    title: "成交时间",
                    width: 150,
                    align: "center",
                    key: "cjsj",
                },
                {
                    title: "面积（m²）",
                    width: 150,
                    align: "center",
                    key: "mj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "面积"), h("div", "（m²）")]);
                    },
                },
                {
                    title: "居室类型",
                    width: 150,
                    align: "center",
                    key: "jslx",
                },
                {
                    title: "单价（元/m²）",
                    width: 150,
                    align: "center",
                    key: "dj",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "单价"), h("div", "（元/m²）")]);
                    },
                },
                {
                    title: "总价（万元）",
                    align: "center",
                    key: "zj",
                    render: (h, params) => {
                        return h("div", params.row.zj == "" ? "" : params.row.zj / 10000);
                    },
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "总价"), h("div", "（万元）")]);
                    },
                },
            ],
            powerData: [],
            searchList: [],
            searchValue: "",
            searchXqid: 0,
            searchLoading: false,
            localSearch: null,
            nearbySearch: null,
            ckalGpList: [],
            ckalCjList: [],
            myChart: "",
            chart5: "",
            chart6: "",
            chart7: "",
            chart8: "",
            chart9: "",
            searchListData: [],
            yp: {},
            td: {
                key: [{
                        title: "时间",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "landTime",
                    },
                    {
                        title: "城市",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "provinceName",
                    },
                    {
                        title: "土地用途",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "landUseName",
                    },
                    {
                        title: "成交宗数",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "religion",
                    },
                    {
                        title: "成交面积（㎡）",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "bargainArea",
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.bargainArea && params.row.bargainArea.toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "成交面积"), h("div", "（㎡）")]);
                        },
                    },
                    {
                        title: "成交面积同比",
                        align: "center",
                        tooltip: true,
                        width: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.bargainAreaWith &&
                                (params.row.bargainAreaWith * 100).toFixed(2) + "%"
                            );
                        },
                        key: "bargainAreaWith",
                    },
                    {
                        title: "成交建面（㎡）",
                        align: "center",
                        tooltip: true,
                        width: 150,
                        key: "bargainBuild",
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.bargainBuild && params.row.bargainBuild.toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "成交建面"), h("div", "（㎡）")]);
                        },
                    },
                    {
                        title: "成交建面同比",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.bargainBuildWith &&
                                (params.row.bargainBuildWith * 100).toFixed(2) + "%"
                            );
                        },
                        key: "bargainBuildWith",
                    },
                    {
                        title: "起始价（元/㎡）",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "startPrice",
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.startPrice == "" ?
                                "" :
                                (params.row.startPrice / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "起始价"), h("div", "（万元）")]);
                        },
                    },
                    {
                        title: "起始价同比",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.startPriceWith &&
                                (params.row.startPriceWith * 100).toFixed(2) + "%"
                            );
                        },
                        key: "startPriceWith",
                    },
                    {
                        title: "成交价",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.transactionPrice == "" ?
                                "" :
                                (params.row.transactionPrice / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "成交价"), h("div", "（万元）")]);
                        },
                        key: "transactionPrice",
                    },
                    {
                        title: "成交价同比",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.transactionPriceWith &&
                                (params.row.transactionPriceWith * 100).toFixed(2) + "%"
                            );
                        },
                        key: "transactionPriceWith",
                    },
                    {
                        title: "楼面价（元/㎡）",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "floorPrice",
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.floorPrice && params.row.floorPrice.toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "楼面价"), h("div", "（元/㎡）")]);
                        },
                    },
                    {
                        title: "楼面价同比",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.floorPriceWith ?
                                (params.row.floorPriceWith * 100).toFixed(2) + "%" :
                                ""
                            );
                        },
                        key: "floorPriceWith",
                    },
                    {
                        title: "溢价率",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.premiumRate ?
                                (params.row.premiumRate * 100).toFixed(2) + "%" :
                                ""
                            );
                        },
                        key: "premiumRate",
                    },
                ],
                data: [],
            },
            sfpm: {
                key: [{
                        title: "楼盘名称",
                        width: 150,
                        align: "center",
                        key: "xqmc",
                    },
                    {
                        title: "成交时间",
                        width: 150,
                        align: "center",
                        key: "cjsj",
                    },
                    {
                        title: "面积（㎡）",
                        width: 150,
                        align: "center",
                        key: "mj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "面积"), h("div", "（㎡）")]);
                        },
                    },
                    {
                        title: "居室类型",
                        width: 150,
                        align: "center",
                        key: "jslx",
                    },
                    {
                        title: "单价（元/㎡）",
                        width: 150,
                        align: "center",
                        key: "dj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
                        },
                    },
                    {
                        title: "总价(万元)",
                        align: "center",
                        key: "zj",
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.zj == "" ? "" : (params.row.zj / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "总价"), h("div", "(万元)")]);
                        },
                    },
                ],
                data: [],
                total: 0,
            },
            cqjys: {
                key: [{
                        title: "楼盘名称",
                        width: 150,
                        align: "center",
                        key: "xqmc",
                    },
                    {
                        title: "成交时间",
                        width: 150,
                        align: "center",
                        key: "cjsj",
                    },
                    {
                        title: "面积（㎡）",
                        width: 150,
                        align: "center",
                        key: "mj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "面积"), h("div", "（㎡）")]);
                        },
                    },
                    {
                        title: "居室类型",
                        width: 150,
                        align: "center",
                        key: "jslx",
                    },
                    {
                        title: "单价（元/㎡）",
                        width: 150,
                        align: "center",
                        key: "dj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
                        },
                    },
                    {
                        title: "总价(万元)",
                        align: "center",
                        key: "zj",
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.zj == "" ? "" : (params.row.zj / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "总价"), h("div", "(万元)")]);
                        },
                    },
                ],
                data: [],
                total: 0,
            },
            xqyp: {
                key: [{
                        title: "序号",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        type: "index",
                    },

                    {
                        title: "证载地址",
                        key: "zzdz",
                        align: "center",
                        width: 300,
                        tooltip: true,

                        ellipsis: true,
                        render: (h, params) => {
                            return h(
                                "a", {
                                    attrs: {
                                        class: "btn-detail",
                                    },
                                    on: {
                                        click: () => {
                                            const routeUrl = this.$router.resolve({
                                                path: "/Empowerment/pledge/pledgeManage/result",
                                                query: {
                                                    taskId: params.row.result.taskId,
                                                    zzdz: params.row.zzdz,
                                                    resultId: params.row.result.id
                                                },
                                            });

                                            window.open(routeUrl.href, "_blank");
                                        },
                                    },
                                },
                                [
                                    h(
                                        "Tooltip", {
                                            props: {
                                                transfer: true,
                                                theme: "light",
                                            },
                                        },
                                        [
                                            params.row.zzdz, //控制树形显示的内容
                                            h(
                                                "span", {
                                                    slot: "content",
                                                    style: {
                                                        whiteSpace: "normal",
                                                    },
                                                },
                                                params.row.zzdz //控制Tooltip显示的内容
                                            ),
                                        ]
                                    ),
                                    h("span", {
                                        style: {
                                            display: "inline-block",
                                            float: "right",
                                            marginRight: "32px",
                                        },
                                    }),
                                ]
                            );
                        },
                    },
                    {
                        title: "建筑面积(㎡)",
                        align: "center",
                        width: 150,
                        key: "jzmj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "建筑面积"), h("div", "（m²）")]);
                        },
                    },

                    {
                        title: "估价基准日",
                        align: "center",
                        width: 150,
                        key: "evalTime",

                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.evalTime && formatDate(params.row.evalTime)
                            );
                        },
                    },
                    {
                        title: "单价（元/㎡）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.pgdj);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
                        },
                    },
                    {
                        title: "总价（万元）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.result.pgzj == "" ?
                                "" :
                                (params.row.result.pgzj / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "总价"), h("div", "（万元）")]);
                        },
                    },
                    {
                        title: "单价-修正（元/㎡）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.fjxzhzDj);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [
                                h("div", "单价-修正"),
                                h("div", "（元/㎡）"),
                            ]);
                        },
                    },
                    {
                        title: "总价-修正（万元）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.result.fjxzhzZj == "" ?
                                "" :
                                (params.row.result.fjxzhzZj / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [
                                h("div", "总价-修正"),
                                h("div", "（万元）"),
                            ]);
                        },
                    },
                    {
                        title: "风险预警(万元)",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.result.yjz == "" ?
                                "" :
                                params.row.result.yjz > 10000 ?
                                (params.row.result.yjz / 10000).toFixed(0) :
                                params.row.result.yjz
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "风险预警"), h("div", "(万元)")]);
                        },
                    },
                    {
                        title: "抵质押率",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.dzyl * 100 + "%");
                        },
                    },
                    {
                        title: "最高可用担保额度",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.result.zgkdbed ?
                                (params.row.result.zgkdbed / 10000).toFixed(2) :
                                ""
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [
                                h("div", "最高可用担保额度"),
                                h("div", "（万元）"),
                            ]);
                        },
                    },
                    {
                        title: "租金价格（元/㎡/月）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.zjdjpg);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "租金价格"), h("div", "（元/㎡/月）")]);
                        },
                    },
                    {
                        title: "租金（元/月）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.zjzjpg);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "租金"), h("div", "（元/月）")]);
                        },
                    },
                    /* {
                                 title: "租金价格-修正（元/㎡/月）",
                                            align: 'center',
                                 render: (h, params) => {
                                   return h('div', params.row.result.zjxzhzJg)
                                 }
                               },
                               {
                                 title: "租金-修正（元/月）",
                                            align: 'center',
                                 render: (h, params) => {
                                   return h('div', params.row.result.zjxzhzZj)
                                 }
                               },*/
                ],
                key2: [{
                        title: "序号",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        type: "index",
                    },

                    {
                        title: "证载地址",
                        key: "zzdz",
                        align: "center",
                        width: 300,
                        tooltip: true,

                        ellipsis: true,
                        render: (h, params) => {
                            return h(
                                "a", {
                                    attrs: {
                                        class: "btn-detail",
                                    },
                                    on: {
                                        click: () => {
                                            const routeUrl = this.$router.resolve({
                                                path: "/Empowerment/pledge/pledgeManage/result",
                                                query: {
                                                    taskId: params.row.result.taskId,
                                                    zzdz: params.row.zzdz,
                                                    id: params.row.result.id
                                                },
                                            });

                                            window.open(routeUrl.href, "_blank");
                                        },
                                    },
                                },
                                [
                                    h(
                                        "Tooltip", {
                                            props: {
                                                transfer: true,
                                                theme: "light",
                                            },
                                        },
                                        [
                                            params.row.zzdz, //控制树形显示的内容
                                            h(
                                                "span", {
                                                    slot: "content",
                                                    style: {
                                                        whiteSpace: "normal",
                                                    },
                                                },
                                                params.row.zzdz //控制Tooltip显示的内容
                                            ),
                                        ]
                                    ),
                                    h("span", {
                                        style: {
                                            display: "inline-block",
                                            float: "right",
                                            marginRight: "32px",
                                        },
                                    }),
                                ]
                            );
                        },
                    },
                    {
                        title: "小区名字",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "xqmc",
                    },
                    {
                        title: "楼栋",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "ld",
                    },
                    {
                        title: "单元",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "dy",
                    },
                    {
                        title: "房号",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "fh",
                    },
                    {
                        title: "建筑面积",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "jzmj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "建筑面积"), h("div", "（m²）")]);
                        },
                    },
                    {
                        title: "所在楼层",
                        align: "center",
                        width: 150,
                        tooltip: true,

                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.szlc +
                                `${params.row.zlc ? "/" + params.row.szlc : ""}`
                            );
                        },
                    },
                    {
                        title: "朝向",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "cx",
                    },
                    {
                        title: "房屋户型",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "fwhx",
                    },
                    {
                        title: "物业类型",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "wylx",
                    },
                    {
                        title: "建筑年代",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "jcnd",
                    },
                    {
                        title: "户型结构",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "hxjg",
                    },
                    {
                        title: "建筑结构",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "jzjg",
                    },
                    {
                        title: "建筑类型",
                        align: "center",
                        width: 150,
                        tooltip: true,
                        key: "jzlx",
                    },
                ],
                data: [],
                total: 0,
            },
            spf: {
                key: [{
                        title: "时间",
                        align: "center",
                        key: "landTime",
                        width: 120,
                    },
                    {
                        title: "城市",
                        align: "center",
                        key: "shi",
                        width: 120,
                    },
                    {
                        title: "行政区",
                        align: "center",
                        key: "xzq",
                        width: 120,
                    },
                    {
                        title: "物业类型",
                        align: "center",
                        key: "wylx",
                        width: 120,
                    },
                    {
                        title: "供应套数",
                        align: "center",
                        key: "gyts",
                        width: 120,
                    },
                    {
                        title: "供应面积（万㎡）",
                        align: "center",
                        width: 120,
                        key: "gymj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "供应面积"), h("div", "（万㎡）")]);
                        },
                    },
                    {
                        title: "成交套数",
                        align: "center",
                        key: "cjts",
                        width: 120,
                    },
                    {
                        title: "成交面积（万㎡）",
                        align: "center",
                        width: 120,
                        key: "cjmj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "成交面积"), h("div", "（万㎡）")]);
                        },
                    },
                    {
                        title: "成交均价（元/㎡）",
                        align: "center",
                        width: 120,
                        key: "cjjj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "成交均价"), h("div", "（元/㎡）")]);
                        },
                    },
                    {
                        title: "成交金额（亿元）",
                        align: "center",
                        width: 120,
                        key: "cjje",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "成交金额"), h("div", "（亿元）")]);
                        },
                    },
                    {
                        title: "库存套数",
                        align: "center",
                        key: "ccts",
                        width: 120,
                    },
                    {
                        title: "库存面积（万㎡）",
                        align: "center",
                        width: 120,
                        key: "ccmj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "库存面积"), h("div", "（万㎡）")]);
                        },
                    },
                    {
                        title: "去化周期",
                        align: "center",
                        key: "qhzq",
                        width: 120,
                    },
                ],
                data: [],
                total: 0,
            },
            ypXq: {},
            ypLs: {
                key: [{
                        title: "序号",
                        align: "center",
                        width: 100,
                        type: "index",
                    },
                    {
                        title: "证载地址",
                        key: "zzdz",
                        align: "center",
                        width: 300,
                        tooltip: true,
                        ellipsis: true,
                    },
                    {
                        title: "估价基准日",
                        align: "center",
                        width: 150,
                        key: "evalTime",
                        render: (h, params) => {
                            return h("div", formatDate(params.row.evalTime));
                        },
                    },
                    {
                        title: "单价（元/㎡）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.pgdj);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
                        },
                    },
                    {
                        title: "总价（万元）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.result.pgzj == "" ?
                                "" :
                                (params.row.result.pgzj / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "总价"), h("div", "（万元）")]);
                        },
                    },
                    {
                        title: "单价-修正（元/㎡）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.fjxzhzDj);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [
                                h("div", "单价-修正"),
                                h("div", "（元/㎡）"),
                            ]);
                        },
                    },
                    {
                        title: "总价-修正（万元）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.result.fjxzhzZj == "" ?
                                "" :
                                (params.row.result.fjxzhzZj / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [
                                h("div", "总价-修正"),
                                h("div", "（万元）"),
                            ]);
                        },
                    },
                    {
                        title: "预警值(万元)",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.result.yjz == "" ?
                                "" :
                                params.row.result.yjz > 10000 ?
                                (params.row.result.yjz / 10000).toFixed(0) :
                                params.row.result.yjz
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "预警值"), h("div", "(万元)")]);
                        },
                    },
                    {
                        title: "风险预警",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            const obj = ["正常", "黄色", "橙色", "红色"];
                            return h(
                                "div",
                                params.row.result.yjLevel ? obj[params.row.result.yjLevel] : ""
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "风险预警")]);
                        },
                    },
                    {
                        title: "租金价格（元/㎡/月）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.zjdjpg);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "租金价格"), h("div", "（元/㎡/月）")]);
                        },
                    },
                    {
                        title: "租金（元/月）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.zjzjpg);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "租金"), h("div", "（元/月）")]);
                        },
                    },
                    {
                        title: "租金价格-修正（元/㎡/月）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.zjxzhzJg);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [
                                h("div", "租金价格-修正"),
                                h("div", "（元/㎡/月）"),
                            ]);
                        },
                    },
                    {
                        title: "租金-修正（元/月）",
                        align: "center",
                        width: 150,
                        render: (h, params) => {
                            return h("div", params.row.result.zjxzhzZj);
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "租金-修正"), h("div", "（元/月）")]);
                        },
                    },
                ],
                data: [],
            },
            ynpg: {
                key: [{
                        title: "委托估价方",
                        align: "center",
                        width: 150,
                        key: "wtpgr",
                        render: (h, params) => {
                            return h(
                                "span",
                                params.row.wtpgr[0] +
                                "****" +
                                params.row.wtpgr[params.row.wtpgr.length - 1]
                            );
                        },
                    },
                    {
                        title: "估价基准日",
                        align: "center",
                        width: 150,
                        key: "pgjzr",
                    },
                    {
                        title: "证载地址",
                        align: "center",
                        width: 300,

                        tooltip: true,
                        ellipsis: true,
                        key: "zzdz",
                    },
                    {
                        title: "单价(元/㎡)",
                        align: "center",
                        width: 150,
                        key: "pgdj",
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "单价"), h("div", "(元/㎡)")]);
                        },
                    },
                    {
                        title: "总价（万元）",
                        align: "center",
                        key: "pgzj",
                        render: (h, params) => {
                            return h(
                                "div",
                                params.row.pgzj == "" ?
                                "" :
                                (params.row.pgzj / 10000).toFixed(0)
                            );
                        },
                        renderHeader: (h, index) => {
                            return h("div", [h("div", "总价"), h("div", "（万元）")]);
                        },
                    },
                ],
                data: [],
            },
            searchAddressList: [],
            searchHaveAddressList: [],
            tabData: {
                厌恶: [],
                交通: [],
                教育: [],
                医疗: [],
                商业: [],
            },
            showSearchList: false,
            spfLoding: false,
            tdLoading: false,
            xqxq: {},
            ckalList: [],
            keyword: "",
        };
    },
    watch: {
        searchListData(newValue) {
            switch (this.tab2) {
                case "tab5":
                    this.tabData["商业"] = newValue;
                    break;
                case "tab2":
                    this.tabData["交通"] = newValue;
                    break;
                case "tab4":
                    this.tabData["医疗"] = newValue;
                    break;
                case "tab3":
                    this.tabData["教育"] = newValue;
                    break;
                default:
                    this.tabData["厌恶"] = newValue;
                    break;
            }
        },
    },

    methods: {
        toImage() {
            this.$btnlog(1015);
            // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
            html2canvas(this.$refs.detail, {
                scale: 2,
                useCORS: true,
                allowTaint: true,
                // taintTest: false,
                imageTimeout: 0,
            }).then((canvas) => {
                // 把生成的base64位图片上传到服务器,生成在线图片地址
                this.htmlToPdf(canvas);
            });
        },
        async htmlToPdf(htmlCanvas) {
            // 将canvas对象转为pdf
            const pdf = this.canvasToPdf(htmlCanvas);
            // 通过浏览器下载pdf
            this.downPdf(pdf, "估价报告");
        },
        downPdf(pdfInstance, title) {
            // 文件名过长导致下载失败
            if (title.length > 50) {
                title = title.substring(title.length - 50);
            }

            pdfInstance.save(title + ".pdf", {
                returnPromise: true
            }).then(() => {
                // 搜狗浏览器下载机制问题暂时不关闭
                // if (!(navigator.userAgent.toLowerCase().indexOf("se 2.x") > -1)) {
                //     setTimeout(window.close, 300);
                // }
            });
        },
        canvasToPdf(htmlCanvas) {
            const canvasWidth = htmlCanvas.width;
            const canvasHeight = htmlCanvas.height;
            const imgBase64 = htmlCanvas.toDataURL("image/jpeg", 1.0);

            // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
            const imgWidth = 595.28;
            // 图片高度需要等比缩放
            const imgHeight = (595.28 / canvasWidth) * canvasHeight;

            let pageHeight = imgHeight; // pdf转化后页面总高度
            let position = 0;

            const pdfInstance = new jsPdf("", "pt", "a4");
            pdfInstance.setFontSize(12);

            if (imgHeight < 841.89) {
                pdfInstance.addImage(imgBase64, "JPEG", 0, 0, imgWidth, imgHeight);
            } else {
                while (pageHeight > 0) {
                    pdfInstance.addImage(
                        imgBase64,
                        "JPEG",
                        0,
                        position,
                        imgWidth,
                        imgHeight
                    );
                    pageHeight -= 841.89;
                    position -= 841.89;
                    if (pageHeight > 0) {
                        pdfInstance.addPage();
                    }
                }
            }

            return pdfInstance;
        },
        changeMapTab(index) {
            this.mapTab = index;
        },
        _yp_xq_zbxq() {
            yp_xq_zbxq(this.params).then((res) => {
                //初始化周边楼盘
                this.ckalList = []
                const myPoint = new BMap.Point(
                    this.mapData.center.lng,
                    this.mapData.center.lat
                );
                res.forEach((item) => {
                    if (item.lpmc == this.yp.xqmc) return;
                    const point = new BMap.Point(item.jd, item.wd);
                    if (+this.map.getDistance(point, myPoint).toFixed(2) < 2500) {
                        item.juli = +this.map.getDistance(point, myPoint).toFixed(2);
                        this.ckalList.push(item);
                    }
                });
                this.ckalList.sort((a, b) => a.juli - b.juli);
                this.ckalList = this.ckalList.splice(0, 5);
                //this.ckalList.sort(compare);
            });
        },
        searchHandle() {
            if (
                !this.searchAddressList.some((item) => item.xqName == this.searchValue)
            )
                return this.$msg.error({
                    text: "请输入或选择正确的小区名称"
                });

            // this.$msg.error("请输入正确的小区");
            this.activeTab = 0;
            this.params.xqId = this.searchXqid;
            this.params.xqmc = this.searchValue;
            this.init();
        },
        changeSearch(data) {
            this.params.cs = this.searchList.filter(
                (item) => item.csId == data
            )[0].cs;
            this.yp.cs = this.params.cs;
            this.params.csId = this.searchList.filter(
                (item) => item.csId == data
            )[0].csId;
            this.searchValue = "";
            this.searchXqid = 0;
            xiaoqu({
                csId: this.params.csId,
            }).then((res) => {
                //
                this.showSearchList = true;
                this.searchAddressList = [];
                res.map((item) => this.searchAddressList.push(item));
                this.searchHaveAddressList = this.searchAddressList;
            });
        },
        showDetailHandle() {
            this.$refs.content.parentNode.scrollTop = 0;
            this.showDetail = true;
            const value = {
                tab1: [
                    "垃圾场",
                    "垃圾焚烧厂",
                    "垃圾填埋场",
                    "殡葬服务",
                    "坟墓",
                    "陵园",
                    "化工厂",
                    "核电站",
                    "危险品仓库",
                    "污水处理厂",
                ],
                tab2: [
                    "地铁",
                    "火车站",
                    "高铁站",
                    "汽车站",
                    "飞机场",
                    "港口",
                    "停车场",
                    "加油站",
                    "公交站",
                    "轻轨",
                ],
                tab3: [
                    "幼儿园",
                    "小学",
                    "中学",
                    "高等院校",
                    "成人教育",
                    "科研机构",
                    "图书馆",
                    "科技馆",
                    "大学",
                    "学院",
                ],
                tab4: [
                    "医院",
                    "药店",
                    "疗养院",
                    "体检机构",
                    "急救中心",
                    "疾控中心",
                    "医疗保健",
                ],
                tab5: [
                    "购物中心",
                    "百货商场",
                    "超级市场",
                    "星级酒店",
                    "酒店",
                    "美食",
                    "休闲娱乐",
                    "银行",
                    "旅游景点",
                    "写字楼",
                ],
            };

            this.chart9 && this.chart9.dispose();
            Object.keys(value).forEach((item, index) => {
                setTimeout(() => {
                    this.searchNearBy(value[item], this.searchWidth * 1000);
                }, 200 * index);
            });
            this.$nextTick(() => {
                this.loadMap2(BMap);
                this.initCharItem();
            });
        },
        hideDetailHandle() {
            this.$refs.content.parentNode.scrollTop = 0;
            this.showDetail = false;
            this.disChart();
            this.$nextTick(() => {
                this.loadMap(BMap);
            });
        },
        toRegionResult() {
            this.$router.push("/Insight/pledge/pledgeManage/regionResult");
        },
        initCharItem() {
            this.myChart = this.$echarts.init(document.getElementById("chart2"));
            this.chart5 = this.$echarts.init(document.getElementById("chart5"));
            this.chart6 = this.$echarts.init(document.getElementById("chart6"));
            this.chart7 = this.$echarts.init(document.getElementById("chart7"));
            this.chart8 = this.$echarts.init(document.getElementById("chart8"));
            this.myChart.setOption(this.option3);
            this.chart5.setOption(this.option);
            this.chart6.setOption(this.option2);
            this.chart7.setOption(this.option3);
            this.chart8.setOption(this.option3);
        },
        disChart() {
            this.myChart && this.myChart.dispose();
            this.chart5 && this.chart5.dispose();
            this.chart6 && this.chart6.dispose();
            this.chart7 && this.chart7.dispose();
            this.chart8 && this.chart8.dispose();
        },

        loadMap(BMap) {
            let that = this;
            this.map = new BMap.Map("map");
            // 创建地址解析器实例
            var myGeo = new BMap.Geocoder();
            // 将地址解析结果显示在地图上,并调整地图视野
            myGeo.getPoint(
                this.searchValue,
                function (point) {
                    if (point) {
                        // that.mapData.center = point;
                        const myPoint = new BMap.Point(
                            that.mapData.center.lng,
                            that.mapData.center.lat
                        );
                        const icon = new BMap.Icon(that.liveIcon, new BMap.Size(50, 50));
                        icon.setSize(new BMap.Size(50, 50));
                        icon.setImageSize(new BMap.Size(50, 50));
                        that.map.enableScrollWheelZoom();
                        let marker = new BMap.Marker(myPoint, {
                            icon
                        });

                        that.map.addOverlay(marker);
                        marker.addEventListener("click", () => {
                            that.map.centerAndZoom(myPoint, 14);
                            //点击时添加小区名字
                            that.addInfo(that,myPoint)
                        });
                        that.map.centerAndZoom(myPoint, 13);
                        that.initNearBySearch(myPoint); // 初始化周边搜索
                    } else {
                        alert("您选择地址没有解析到结果!");
                    }
                },
                "中国"
            );
            this._yp_xq_zbxq();
        },
        //点击后显示小区名字
        addInfo(vm, point) {
            var opts = {
                position: point, // 指定文本标注所在的地理位置
                offset: new BMap.Size(0, -80), // 设置文本偏移量
            };
            let info = new BMap.Label(vm.yp.xqmc, opts);
            // 自定义文本标注样式
            info.setStyle({
                color: "red",
                borderRadius: "5px",
                borderColor: "red",
                fontSize: "16px",
                height: "40px",
                lineHeight: "36px",
                fontFamily: "微软雅黑",
                paddingLeft: '10px',
                paddingRight: '10px',
            });
            info.name = 'title'
            vm.map.addOverlay(info);
        },
        initNearBySearch(myPoint) {
            let that = this;
            this.nearbySearch = new BMap.LocalSearch(this.map, {
                renderOptions: {
                    map: this.map,
                    autoViewport: false,
                    selectFirstResult: false,
                },
                onSearchComplete(result) {
                    that.keyword = result[0].keyword;
                },
                onMarkersSet(result) {
                    const myPoint = new BMap.Point(
                        that.mapData.center.lng,
                        that.mapData.center.lat
                    );
                    // 去重
                    let newRes = [];
                    for (let i = 0; i < result.length; i++) {
                        if (
                            newRes.findIndex(
                                (nr) =>
                                nr.point.lng === result[i].point.lng &&
                                nr.point.lat === result[i].point.lat
                            ) < 0
                        ) {
                            newRes.push(result[i]);
                        }
                    }
                    newRes.forEach((item) => {
                        const point = new BMap.Point(item.point.lng, item.point.lat);
                        item.juli = +that.map.getDistance(point, myPoint).toFixed(2);
                    });
                    newRes = newRes.filter((item) => item.juli <= 5000);
                    newRes.sort((a, b) => {
                        return a.juli - b.juli;
                    });
                    newRes = newRes.map((item) => {
                        if (Array.isArray(item.tags) && item.tags.length > 0) {
                            item.tag = item.tags[item.tags.length - 1];
                        }
                        return item;
                    });
                    if (that.showDetail) {
                        switch (that.keyword) {
                            case "购物":
                                that.tabData["商业"] = newRes;
                                break;
                            case "地铁":
                                that.tabData["交通"] = newRes;
                                break;
                            case "医院":
                                that.tabData["医疗"] = newRes;
                                break;
                            case "幼儿园":
                                that.tabData["教育"] = newRes;
                                break;
                            default:
                                that.tabData["厌恶"] = newRes;
                                break;
                        }
                    } else {
                        that.searchListData = newRes;
                    }
                },
            });
        },
        chooseSearchAddress(item) {
            this.searchValue = item.xqName;
            this.searchXqid = item.id;
            this.searchHaveAddressList = [];
        },
        inputSearch() {
            this.searchHaveAddressList = [];
            this.searchAddressList.map((item) => {
                if (item.xqName.indexOf(this.searchValue) > -1) {
                    this.showSearchList = true;
                    this.searchHaveAddressList.push(item);
                }
            });
        },
        searchNearBy(searchType, range) {
            this.map.clearOverlays();
            const myPoint = new BMap.Point(
                this.mapData.center.lng,
                this.mapData.center.lat
            );
            const icon = new BMap.Icon(this.liveIcon, new BMap.Size(50, 50));
            icon.setSize(new BMap.Size(50, 50));
            icon.setImageSize(new BMap.Size(50, 50));
            const marker = new BMap.Marker(myPoint, {
                icon
            });
            marker.addEventListener("click", () => {
                this.map.centerAndZoom(myPoint, 14);
                //点击时添加小区名字
                this.addInfo(this,myPoint)
            });
            this.map.addOverlay(marker);
            this.addCircle(myPoint, range);
            this.nearbySearch.searchNearby(searchType, myPoint, range * 1);
        },
        loadMap2(BMap) {
            this.map = new BMap.Map("map2");
            const icon = new BMap.Icon(this.liveIcon, new BMap.Size(50, 50));
            const myPoint = new BMap.Point(
                this.mapData.center.lng,
                this.mapData.center.lat
            );
            icon.setSize(new BMap.Size(50, 50));
            icon.setImageSize(new BMap.Size(50, 50));
            const marker = new BMap.Marker(myPoint, {
                icon
            });
            marker.addEventListener("click", () => {
                this.map.centerAndZoom(myPoint, 14);
                //点击时添加小区名字
                this.addInfo(this,myPoint)

            });
            this.map.addOverlay(marker);
            this.map.centerAndZoom(
                new BMap.Point(this.mapData.center.lng, this.mapData.center.lat),
                this.mapData.zoom
            );
            this.map.enableScrollWheelZoom();
        },
        handleSpan({
            row,
            column,
            rowIndex,
            columnIndex
        }) {
            if (rowIndex % 10 == 0 && columnIndex === 0) {
                return {
                    rowspan: 10,
                    colspan: 1,
                };
            } else if (rowIndex % 10 == 0 && columnIndex === 1) {
                return {
                    rowspan: 10,
                    colspan: 1,
                };
            } else if (rowIndex % 5 == 0 && columnIndex === 2) {
                return {
                    rowspan: 5,
                    colspan: 1,
                };
            } else if (rowIndex % 10 !== 0 && columnIndex === 0) {
                return {
                    rowspan: 0,
                    colspan: 0,
                };
            } else if (rowIndex % 10 !== 0 && columnIndex === 1) {
                return {
                    rowspan: 0,
                    colspan: 0,
                };
            } else if (rowIndex % 5 !== 0 && columnIndex === 2) {
                return {
                    rowspan: 0,
                    colspan: 0,
                };
            }
        },
        chooseTab(item) {
            this.activeTab = item.id;
            if (!this.map) return;
            this.ckalList.forEach((item) => {
                if (item.marker) {
                    this.map.removeOverlay(item.marker);
                }
            });
            if (item.id == 2) {
                this.chart9 && this.chart9.dispose();
                this.searchItem = [
                    "地铁",
                    "火车站",
                    "高铁站",
                    "汽车站",
                    "飞机场",
                    "港口",
                    "停车场",
                    "加油站",
                    "公交站",
                ];
                this.searchNearBy(this.searchItem, this.searchWidth * 1000);
            } else if (item.id == 4) {
                this.$nextTick(() => {
                    this.chart9 = this.$echarts.init(document.getElementById("chart9"));
                    this.chart9.setOption(this.option3);
                });
            } else {
                this.chart9 && this.chart9.dispose();
                this.map.clearOverlays();
                const icon = new BMap.Icon(this.liveIcon, new BMap.Size(50, 50));
                const myPoint = new BMap.Point(
                    this.mapData.center.lng,
                    this.mapData.center.lat
                );
                icon.setSize(new BMap.Size(50, 50));
                icon.setImageSize(new BMap.Size(50, 50));
                const marker = new BMap.Marker(myPoint, {
                    icon
                });
                marker.addEventListener("click", () => {
                    this.map.centerAndZoom(myPoint, 14);
                    //点击时添加小区名字
                this.addInfo(this,myPoint)

                });
                this.map.addOverlay(marker);
            }
            if (this.activeTab == 1) {
                let that = this
                this.ckalList.forEach((item) => {
                    var point = new BMap.Point(item.jd, item.wd);
                    item.marker = new BMap.Marker(point); // 创建标注
                    this.map.addOverlay(item.marker);
                    item.marker.addEventListener("click", function () {
                        that.deleteMapOverlay('title')
                         that.addInfoWindow(this,item)
                    });
                });
                const myPoint = new BMap.Point(
                    this.mapData.center.lng,
                    this.mapData.center.lat
                );

                this.addCircle(myPoint, 3000);
            }
        },
         //删除标注
        deleteMapOverlay(name){
            let allOverlays = this.map.getOverlays()
            allOverlays.forEach(overLay=>{
                if(overLay.name == name){
                     this.map.removeOverlay(overLay)
                }
            })
        },
        //添加点击显示信息事件
        addInfoWindow(vm,item) {
            var point = new BMap.Point(item.jd, item.wd);
            var opts = {
                width: 100, // 信息窗口宽度
                height: 50, // 信息窗口高度
                title: item.lpmc, // 信息窗口标题
                enableMessage: true, //设置允许信息窗发送短息
                message: `${item.dj || "--"}元/m²`,
                offset: new BMap.Size(0, -20),
            };
            var infoWindow = new BMap.InfoWindow(`${item.dj || "--"}元/m²`, opts); // 创建信息窗口对象
             vm.map.openInfoWindow(infoWindow, point)
        },
        addCircle(point, long) {
            const circle = new BMap.Circle(point, long, {
                strokeColor: "#00b6ff",
                strokeWeight: 3,
                strokeOpacity: 1,
                strokeStyle: "dashed",
                fillColor: "",
            });
            this.map.addOverlay(circle);
        },
        chooseTab2(name) {
            const value = {
                tab1: [
                    "垃圾场",
                    "垃圾焚烧厂",
                    "垃圾填埋场",
                    "殡葬服务",
                    "坟墓",
                    "陵园",
                    "化工厂",
                    "核电站",
                    "危险品仓库",
                    "污水处理厂",
                ],
                tab2: [
                    "地铁",
                    "火车站",
                    "高铁站",
                    "汽车站",
                    "飞机场",
                    "港口",
                    "停车场",
                    "加油站",
                    "公交站",
                    "轻轨",
                ],
                tab3: [
                    "幼儿园",
                    "小学",
                    "中学",
                    "高等院校",
                    "成人教育",
                    "科研机构",
                    "图书馆",
                    "科技馆",
                    "大学",
                    "学院",
                ],
                tab4: [
                    "医院",
                    "药店",
                    "疗养院",
                    "体检机构",
                    "急救中心",
                    "疾控中心",
                    "医疗保健",
                ],
                tab5: [
                    "购物中心",
                    "百货商场",
                    "超级市场",
                    "星级酒店",
                    "酒店",
                    "美食",
                    "休闲娱乐",
                    "银行",
                    "旅游景点",
                    "写字楼",
                ],
            };
            this.searchItem = value[name];
            this.searchNearBy(this.searchItem, this.searchWidth * 1000);
        },
        _yp_wylx() {
            yp_wylx().then((res) => {
                //初始化物业类型列表
                    this.tableOption3[0].optionList = []
                res.forEach((i) => {
                    this.tableOption3[0].optionList.push({
                        label: i,
                        value: i,
                    });
                });
            });
        },
        _yp_xq_zxq() {
            yp_xq_zxq({
                xqmc: this.params.xqmc,
                xqId: this.params.xqId,
            }).then((res) => {
                //初始化行政区列表
                    this.tableOption3[1].optionList = []
                res.forEach((i) => {
                    this.tableOption3[1].optionList.push({
                        label: i.name,
                        value: i.id,
                    });
                });
            });
        },
        _rwlb(choose = true) {
            cs({
                current: 1,
                size: 9999,
            }).then((res) => {
                this.searchList = res;
                if (this.params.csId == "") {
                    this.params.cs = this.searchList[0].cs;
                    this.params.csId = this.searchList[0].csId;
                    this.yp.cs = this.searchList[0].cs;
                } else {
                    this.params.cs = this.searchList.filter(
                        (item) => item.csId == this.params.csId
                    )[0].cs;
                }
                xiaoqu({
                    csId: this.params.csId,
                }).then((res) => {
                    this.searchAddressList = [];
                    res.map((item) => this.searchAddressList.push(item));
                    this.searchHaveAddressList = this.searchAddressList;
                    if (choose) {
                        this.showSearchList = true;
                        this.chooseSearchAddress(this.searchHaveAddressList[0]);
                        this.searchHandle();
                    }
                });
            });
        },
        _one_yp() {

            yp_xqpg(this.params).then((res) => {
                this.yp = res;
                this.mapData.center = {
                    lng: Number(this.yp.jd),
                    lat: Number(this.yp.wd),
                };
                this.searchValue = res.xqmc;
                this.loadMap(BMap);
            });
        },
        _yp_cjal() {
            yp_xq_cjal(
                Object.assign(this.params, {
                    current: 1,
                    size: 10,
                })
            ).then((res) => {
                // let _baseTime = this.yp.pgsj;
                // let halfYear = _baseTime - 365 * 24 * 60 * 60 * 1000;
                // //基准日
                // let _list = res.records.filter((item) => {
                //   // return Date.parse(item.cjsj) < _baseTime;
                //   return Date.parse(item.cjsj) < _baseTime
                //     ? Date.parse(item.cjsj) > halfYear
                //     : "";
                // });
                //对成交案例进行时间限制，在基准日之后的数据都会被过滤掉
                this.ckalCjList = res.records.splice(0, 5);
            });
        },
        _yp_gpal() {
            yp_xq_gpal(
                Object.assign(this.params, {
                    current: 1,
                    size: 10,
                })
            ).then((res) => {
                //  let _baseTime = this.yp.pgsj;
                //   let halfYear = _baseTime - 365 * 24 * 60 * 60 * 1000;
                //   //基准日
                //   let _list = res.records.filter((item) => {
                //     return Date.parse(item.gpsj) <= _baseTime
                //       ? Date.parse(item.gpsj) >= halfYear
                //       : "";
                //   });
                //对挂牌案例进行时间限制，在基准日之后的数据都会被过滤掉
                this.ckalGpList = res.records.splice(0, 5);
            });
        },

        _xqyp_list() {
            xqyp_xq_list(this.params).then((res) => {
                this.xqyp.data = res.records;
                this.ypLs.data = res.records;
                this.xqyp.total = res.total;
            });
        },
        changeXqyp(page) {
            this.params.current = page;
            xqyp_xq_list(this.params).then((res) => {
                this.xqyp.data = res.records;
                this.xqyp.total = res.total;
            });
        },
        pageSizeChangeXqyp(pageSize) {
            this.params.current = 1;
            this.params.size = pageSize;
            xqyp_xq_list(this.params).then((res) => {
                this.xqyp.data = res.records;
                this.xqyp.total = res.total;
            });
        },
        _yp_td() {
            this.tdLoading = true;
            yp_tdtj({
                    current: this.params.current,
                    landUses: this.params.landUses || [],
                    size: this.params.size,
                    provinceIds: this.params.csId,
                    timePeriod: this.params.timePeriod || 1,
                })
                .then((res) => {
                    this.td.data = res.records;
                    this.td.total = res.total;
                })
                .finally(() => {
                    this.tdLoading = false;
                });
        },
        changeTd(page) {
            this.params.current = page;
            this._yp_td();
        },
        changeTdOption(value, name) {
            switch (name) {
                case "土地用途":
                    this.params.landUses = value;
                    break;
                case "周期":
                    this.params.timePeriod = value;
                    break;
            }
            this.params.current = 1;
            this._yp_td();
        },
        pageSizeChangeTd(pageSize) {
            this.params.current = 1;
            this.params.size = pageSize;
            this._yp_td();
        },
        _yp_sfpm() {
            yp_xq_sfpm(this.params).then((res) => {
                this.sfpm.data = res.records;
                this.sfpm.total = res.total;
            });
        },
        changeSfpm(page) {
            this.params.current = page;
            yp_xq_sfpm(this.params).then((res) => {
                this.sfpm.data = res.records;
                this.sfpm.total = res.total;
            });
        },
        pageSizeChangeSfpm(pageSize) {
            this.params.current = 1;
            this.params.size = pageSize;
            yp_xq_sfpm(this.params).then((res) => {
                this.sfpm.data = res.records;
                this.sfpm.total = res.total;
            });
        },
        _yp_cqjys() {
            yp_xq_cqjys(this.params).then((res) => {
                this.cqjys.data = res.records;
                this.cqjys.total = res.total;
            });
        },
        changeCqjys(page) {
            this.params.current = page;
            yp_xq_cqjys(this.params).then((res) => {
                this.cqjys.data = res.records;
                this.cqjys.total = res.total;
            });
        },
        pageSizeChangeCqjys(pageSize) {
            this.params.current = 1;
            this.params.size = pageSize;
            yp_xq_cqjys(this.params).then((res) => {
                this.cqjys.data = res.records;
                this.cqjys.total = res.total;
            });
        },
        _yp_spf() {
            this.spfLoding = true;
            yp_xq_spf(this.params)
                .then((res) => {
                    this.spf.data = res.records;
                    this.spf.total = res.total;
                })
                .finally(() => {
                    this.spfLoding = false;
                });
        },
        changeSpf(page) {
            this.params.current = page;
            this._yp_spf();
        },
        getLandUse() {
            getLandUse2().then((res) => {
                this.tableOption2[0].optionList = res.reduce(
                    (optionArr, item, index) => {
                        optionArr.push({
                            value: item.id,
                            label: item.name
                        });
                        this.$set(this.tableOption2[0].value, index, item.id);
                        return optionArr;
                    },
                    []
                );
            });
        },
        changeSpfOption(value, name) {
            if (!value) return;
            switch (name) {
                case "物业类型":
                    this.params.wylx = value;
                    break;
                case "行政区":
                    this.params.xzq = value;
                    break;
                case "周期":
                    this.params.zq = value == 1 ? ["月"] : ["年"];
                    break;
            }
            this.params.current = 1;
            this._yp_spf();
        },
        pageSizeChangeSpf(pageSize) {
            this.params.current = 1;
            this.params.size = pageSize;
            this._yp_spf();
        },
        _yp_history() {
            yp_xq_history(this.params).then((res) => {
                this.ypLs.data = res.records;
            });
        },
        _yp_ynpg() {
            yp_ynpg(this.params).then((res) => {
                this.ynpg.data = res.records;
            });
        },
        clickPoint(item) {
            item.marker.ba.click();
        },
        clickCkal(item) {
            // item.marker.ba.click();
            this.deleteMapOverlay('title')
            this.addInfoWindow(this,item)
        },
        _yp_jgzs() {
            yp_xq_jgzs(this.params).then((res) => {
                if (res.length > 0) {
                    this.option3.legend.data = [res[0].lpmc, res[0].district, res[0].cs];
                    this.option3.series[0].name = res[0].lpmc;
                    this.option3.series[1].name = res[0].district;
                    this.option3.series[2].name = res[0].cs;
                    let data0 = new Array();
                    let data1 = new Array();
                    let data2 = new Array();
                    let xAxis = new Array();
                    res.forEach((item) => {
                        data0.push(item.lpPrice);
                        data1.push(item.districtPrice);
                        data2.push(item.csPrice);
                        xAxis.push(item.dataDate);
                    });
                    this.option3.series[0].data = data0.reverse();
                    this.option3.series[1].data = data1.reverse();
                    this.option3.series[2].data = data2.reverse();

                    this.option3.xAxis.data = xAxis.reverse();
                }
            });
        },
        _yp_xqxq() {
            yp_xq_xqxq(this.params).then((res) => {
                this.xqxq = res;
            });
        },
        init() {
            this._yp_jgzs();
            this._one_yp();
            this._yp_cjal();
            this._yp_gpal();
            this._xqyp_list();
            this._yp_td();
            this._yp_sfpm();
            this._yp_cqjys();
            this._yp_spf();
            // this._yp_history();
            this._yp_xqxq();
            this._yp_wylx();
            this._yp_xq_zxq();
            this.getLandUse();
        },
        clear() {
            this.showSearchList = false;
        },
        searchByWidth() {
            if (!this.searchWidth) {
                this.$msg.error({
                    type: "remind",
                    code: 4047
                });
                return;
            }
            if (!/^-?\d+(\.\d+)?$/.test(this.searchWidth)) {
                // this.$Message.warning('请输入正确的数字')
                this.$msg.error({
                    type: "remind",
                    code: 4046
                });

                return;
            }
            if (this.searchWidth > 5) {
                this.searchWidth = "5";

                this.$msg.error({
                    type: "remind",
                    code: 4048
                });

                // this.$Message.warning('搜索最大范围是5000米，已为你设置成最大值')
            }
            this.searchNearBy(this.searchItem, this.searchWidth * 1000);
        },
        showTips() {
            this.$msg.error({
                type: "tips",
                code: 3065
            });
        },
    },
    filters: {
        formatDate: function (val) {
            const value = new Date(val);
            const year = value.getFullYear();
            const month = value.getMonth() + 1;
            const day = value.getDate();
            const hour = value.getHours();
            const minutes = value.getMinutes();
            const seconds = value.getSeconds();
            return (
                year +
                "-" +
                (month >= 10 ? month : "0" + month) +
                "-" +
                (day >= 10 ? day : "0" + day)
            );
            // return year + '-' + (month >= 10 ? month : '0' + month) + '-' + (day >= 10 ? day : '0' + day) + " " + (hour >= 10 ? hour : '0' + hour) + ":" + (minutes >= 10 ? minutes : '0' + minutes) + ":" + (seconds >= 10 ? seconds : '0' + seconds);
        },
    },
    mounted() {

        document.addEventListener("click", this.clear);
        if (this.$route.query.xqId) {
            this.params.xqId = this.$route.query.xqId;
            this.params.csId = this.$route.query.csId;
            this.params.cs = this.$route.query.cs;
            this.params.xqmc = this.$route.query.xqmc;
            this.params.evalDate = this.$route.query.evalDate;
            this.searchValue = this.$route.query.xqmc;
            this.searchXqid = this.$route.query.xqId;
        }
        if (
            this.params.xqId != "" &&
            this.params.csId != "" &&
            this.params.cs != "" &&
            this.params.xqmc != ""
        ) {
            this.init();
            this._rwlb(false);
        } else {
            this._rwlb();
        }
    },
    destroyed() {
        document.removeEventListener("click", this.clear);
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.result-box {
    width: 100%;
    // margin: 20px;
    padding: 20px 0;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;

    .detail {
        color: #333333;
        position: relative;

        .detailBox {
            padding: 30px 20px 10px;
        }

        .title {
            font-size: 24px;
            font-weight: bold;
        }

        .sData {
            font-size: 14px;
            margin-top: 10px;

            span {
                margin-right: 60px;
            }
        }

        .backBtn {
            position: absolute;
            top: -10px;
            left: 20px;
        }

        .downloadBtn {
            width: 76px;
            height: 40px;
            @include flex;
            position: absolute;
            background: #00b6ff;
            border-radius: 5px;
            top: 10px;
            right: 20px;
            color: #fff;
            border-color: #00b6ff;
        }

        .grayLine {
            width: 100%;
            height: 1px;
            background: #d6d7d9;
            margin: 20px auto;
        }

        .detailItem {
            .itemTitle {
                font-size: 18px;
                font-weight: bold;
                color: #1562d6;
                margin: 20px auto;
            }

            .itemInfo {
                font-size: 14px;
                margin-bottom: 20px;
            }

            .sTitle {
                font-size: 14px;
                font-weight: bold;
                margin: 10px auto;
            }

            .resultBox {
                display: grid;
                grid-template-columns: repeat(auto-fill, 170px);
                grid-gap: 6px;
                margin-bottom: 20px;

                .resultItem {
                    background: #f3f7ff;
                    height: 100px;
                    text-align: center;

                    .info:nth-of-type(1) {
                        font-size: 14px;
                        color: #1562d6;
                        margin: 10px auto;
                    }

                    .info:nth-of-type(2) {
                        font-size: 16px;
                        color: #1562d6;
                        margin-bottom: 12px;
                    }

                    .info:nth-of-type(3) {
                        font-size: 12px;
                        color: #666;
                    }

                    &.danger {
                        background: #fff3f3;
                        color: #ff3333;

                        .info:nth-of-type(1) {
                            font-size: 14px;
                            color: #ff3333;
                            margin: 10px auto;
                        }

                        .info:nth-of-type(2) {
                            font-size: 16px;
                            color: #ff3333;
                            margin-bottom: 12px;
                        }

                        .info:nth-of-type(3) {
                            font-size: 12px;
                            color: #ff3333;
                        }
                    }
                }
            }

            .map {
                height: 348px;
                width: 100%;
                background: #eee;
                margin-top: 10px;
            }

            .hList {
                display: grid;
                grid-template-columns: repeat(3, minmax(460px, 1fr));
                margin-bottom: 20px;

                .item {
                    height: 110px;
                    width: 100%;
                    position: relative;

                    &::after {
                        content: "";
                        background: #d6d7d9;
                        width: 460px;
                        height: 1px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    img {
                        display: block;
                        position: absolute;
                        top: 14px;
                        left: 0;
                        width: 105px;
                        height: 80px;
                        background: #00b6ff;
                    }

                    .title {
                        font-size: 16px;
                        color: #333;
                        position: absolute;
                        top: 14px;
                        left: 124px;
                    }

                    .size {
                        position: absolute;
                        top: 45px;
                        left: 124px;
                        color: #666;
                        font-size: 14px;

                        span {
                            margin-right: 20px;
                        }
                    }

                    .address {
                        position: absolute;
                        top: 45px;
                        left: 124px;
                        color: #666;
                        font-size: 14px;

                        .mapIcon {
                            height: 16px;
                            width: 16px;
                            transform: translateY(3px);
                        }
                    }

                    .time {
                        color: #333;
                        position: absolute;
                        top: 74px;
                        left: 124px;
                    }

                    .priceItem {
                        position: absolute;
                        left: 340px;
                        display: flex;
                        align-items: center;
                        white-space: nowrap;

                        .icon {
                            width: 18px;
                            height: 18px;
                            font-size: 12px;
                            @include flex-center;
                            margin-left: 7px;
                            border: 1px solid #e0e0e0;
                            border-radius: 2px;
                        }

                        .price {
                            color: #ff3333;
                            font-size: 16px;
                            margin-right: 6px;
                        }

                        .dw {
                            font-size: 12px;
                            color: #333;
                        }

                        &.sPrice {
                            top: 14px;
                        }

                        &.aPrice {
                            top: 43px;

                            .icon {
                                border-color: #ff3333;
                                color: #ff3333;
                            }
                        }
                    }
                }
            }

            .contentInfo {
                height: 633px;
                margin: 20px auto;
                display: flex;
                box-sizing: border-box;

                .left {
                    width: 527px;
                    height: 100%;
                    border: 1px solid #d6d7d9;
                    background: #fcfcff;
                    margin-right: 10px;
                    padding: 20px;
                    overflow: auto;

                    .contentLine {
                        margin-bottom: 20px;
                        display: flex;
                        font-size: 16px;
                        text-align: left;

                        .contentTitle {
                            flex: 1;
                            font-weight: bold;
                            color: #333;
                        }

                        .content {
                            flex: 1.5;
                            color: #666;

                            &.norwap {
                                flex: 4;
                            }
                        }
                    }
                }

                .right {
                    flex: 1;
                    height: 100%;
                    border: 1px solid #d6d7d9;
                    background: #fcfcff;
                    padding: 20px;
                    box-sizing: border-box;

                    #chart2 {
                        height: calc(100% - 59px);
                        width: 100%;
                    }
                }
            }

            .hLine {
                display: flex;

                .htitle {
                    font-size: 14px;
                    font-weight: bold;
                    color: #00b6ff;
                    display: inline;
                    line-height: 30px;
                    width: 50px;
                    min-width: 50px;
                }

                .hitemList {

                    // display: grid;
                    // grid-row-gap: 10px;
                    // grid-column-gap: 10px;
                    // grid-template-rows: repeat(auto-fill, 1fr);
                    .item {
                        height: 30px;
                        line-height: 16px;
                        padding: 7px;
                        background: #f5f5f5;
                        display: inline-block;
                        margin-bottom: 10px;
                        margin-right: 10px;

                        .name {
                            margin-right: 30px;
                        }

                        .type {
                            height: 20px;
                            line-height: 20px;
                            border-radius: 2px;
                            border: 1px solid #ee7d78;
                            color: #ee7d78;
                            font-size: 12px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            .chartItem {
                height: 280px;
                width: 100%;
                margin: 10px auto 20px;
            }
        }
    }

    .content-box {
        margin: 0 20px;

        .search-bar {
            width: 100%;
            height: 76px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            white-space: nowrap;

            .left-title {
                cursor: pointer;
            }

            .center {
                width: 993px;
                height: 76px;
                border: 1px solid #d6d7d9;
                border-radius: 38px;
                margin-left: 231px;
                display: flex;
                position: relative;

                .searchOption {
                    width: 331px;
                    height: 76px;
                    color: #333333;
                    font-size: 20px;
                    @include flex-center;
                    cursor: pointer;
                    position: relative;

                    .downIcon {
                        @include flex-center;
                        right: 34px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #aaaaaa;
                        position: absolute;
                    }
                }

                .searchInput {
                    height: 100%;
                    width: 541px;
                    border: none;
                    border-left: 1px solid #e8e8e8;
                    outline: none;
                    box-sizing: border-box;
                    padding: 0 10px;
                    font-size: 16px;
                }

                .searchBtn {
                    width: 120px;
                    height: 100%;
                    background: #1562d6;
                    box-shadow: 0px 0px 12px 0px rgba(41, 53, 92, 0.2);
                    border-radius: 0px 38px 38px 0px;
                    font-size: 20px;
                }

                .searchTipsBox {
                    width: 650px;
                    position: absolute;
                    top: 80px;
                    left: 224px;
                    background: #fff;
                    z-index: 100;
                    max-height: 600px;
                    overflow: auto;
                    box-shadow: 0 0 10px 1px #e1e1e1;

                    .searchTipsItem {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        padding: 16px 5px 16px;
                        box-sizing: border-box;
                        cursor: pointer;

                        &:hover {
                            background: #f5f5f5;
                        }

                        .searchTipsTitle {
                            height: 28px;
                            background: rgba(67, 115, 232, 1);
                            border-radius: 5px;
                            box-sizing: border-box;
                            padding: 8px 12px;
                            font-size: 14px;
                            @include flex-center;
                            color: #fff;
                        }

                        .key {
                            font-size: 14px;
                            color: #666666;
                            margin-left: 9px;
                        }

                        .item {
                            color: #333333;
                            font-size: 14px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        .top-box {
            width: 100%;
            display: flex;

            .left-top-box {
                width: 480px;

                .place-info {
                    width: 100%;
                    height: 464px;
                    border: 1px solid #d6d7d9;
                    box-sizing: border-box;
                    padding: 20px;

                    .title {
                        font-size: 20px;
                        font-weight: bold;
                        color: #333333;
                    }

                    .time {
                        font-size: 14px;
                        color: #999;
                        margin: 10px 0;
                    }

                    .address {
                        width: 440px;
                        height: 40px;
                        background: #f2f2f2;
                        color: #333333;
                        line-height: 40px;
                        font-size: 16px;

                        .mapIcon {
                            width: 20px;
                            height: 20px;
                            margin: 0 12px;
                            transform: translateY(4px);
                        }
                    }

                    .price {
                        height: 104px;
                        border-bottom: 1px solid #d6d7d9;
                        width: 100%;
                        display: flex;

                        .i-price {
                            height: 100%;
                            flex: 1;
                            text-align: center;

                            .p-title {
                                color: #666666;
                                font-size: 14px;
                                margin: 20px auto 10px;
                            }

                            .p-num {
                                color: #ff3333;
                                font-weight: bold;
                                font-size: 20px;
                                margin-bottom: 3px;
                            }

                            .r-num {
                                color: #666666;
                                font-size: 12px;
                            }
                        }
                    }

                    .other-info {
                        border-bottom: 1px solid #d6d7d9;

                        .top-info {
                            width: 100%;
                            display: flex;
                            font-size: 14px;
                            color: #333333;
                            margin-top: 10px;

                            .item {
                                flex: 1;
                                text-align: center;
                            }
                        }

                        .bottom-info {
                            display: flex;
                            height: 36px;
                            margin: 20px auto 10px;

                            .item {
                                flex: 1;
                                text-align: center;
                            }
                        }
                    }

                    .info-box {
                        display: flex;
                        padding-top: 20px;

                        .item {
                            width: 50%;
                            height: 188px;
                            background: #f3f7ff;
                            text-align: center;
                            margin-right: 6px;

                            &:last-child {
                                margin-right: 0;
                            }

                            .info:nth-child(1) {
                                color: black;
                                font-size: 14px;
                                margin: 70px auto 10px;
                            }

                            .info:nth-child(2) {
                                font-size: 16px;
                                font-weight: bold;
                                color: #1562d6;
                                margin-bottom: 10px;
                            }

                            .info:nth-child(3) {
                                font-size: 12px;
                                color: #666666;
                            }
                        }
                    }
                }

                .other-list {
                    width: 100%;
                    height: 634px;
                    border: 1px solid #d6d7d9;
                    border-top: none;
                    box-sizing: border-box;
                    position: relative;

                    .other-nav-list {
                        height: 60px;
                        width: 100%;
                        display: flex;
                        background: #fcfcff;
                        color: #333333;

                        .item {
                            flex: 1;
                            @include flex-center;
                            cursor: pointer;

                            &:hover,
                            &.active {
                                background: #00b6ff;
                                color: #fff;
                            }
                        }
                    }

                    div.tabList {
                        width: 380px;
                        margin: 0 auto;
                    }

                    .other-tab {
                        background: #f4f4f4;
                        height: 30px;
                        width: 100%;
                        color: #666666;
                        line-height: 30px;
                        text-align: right;

                        .tipsInfo {
                            position: absolute;
                            left: 310px;
                            cursor: pointer;
                        }

                        span {
                            cursor: pointer;
                            margin: 10px;
                            position: relative;

                            &:last-child::after {
                                content: "";
                                position: absolute;
                                left: -10px;
                                width: 1px;
                                height: 14px;
                                top: calc(50% - 7px);
                                background: #666666;
                            }

                            &:hover,
                            &.active {
                                color: #00b6ff;
                            }
                        }
                    }

                    .tabInfo {
                        width: 100%;
                        height: 573px;
                        box-sizing: border-box;
                        overflow: auto;
                        font-size: 16px;

                        .info {
                            display: flex;
                            flex-wrap: wrap;

                            .line {
                                display: flex;
                                height: 50px;
                                width: 100%;
                                align-items: center;

                                &.single {
                                    width: 100%;

                                    .name {
                                        flex: 1;
                                        color: #333;
                                        text-align: right;
                                    }

                                    .data {
                                        flex: 4;
                                        color: #333333;
                                        margin-left: 10px;
                                    }
                                }

                                .name {
                                    flex: 1;
                                    color: #333;
                                    text-align: right;
                                }

                                .data {
                                    flex: 4;
                                    color: #333333;
                                    margin-left: 10px;

                                    &.norwap {
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }

                        #chart9 {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    .other-info-list {
                        width: 100%;
                        height: 545px;
                        background: #fff;
                        box-sizing: border-box;
                        padding: 0 10px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        border-bottom: 1px solid #d6d7d9;

                        .item {
                            height: 110px;
                            border-bottom: 1px solid #d6d7d9;
                            width: 100%;
                            position: relative;

                            &:last-child {
                                border-bottom: none;
                            }

                            img {
                                display: block;
                                position: absolute;
                                top: 14px;
                                left: 9px;
                                width: 105px;
                                height: 80px;
                                background: #00b6ff;
                            }

                            .address {
                                position: absolute;
                                top: 45px;
                                left: 124px;
                                color: #666;
                                font-size: 14px;

                                .mapIcon {
                                    height: 16px;
                                    width: 16px;
                                    transform: translateY(3px);
                                }
                            }

                            .title {
                                font-size: 16px;
                                color: #333;
                                position: absolute;
                                top: 14px;
                                left: 124px;
                            }

                            .size {
                                position: absolute;
                                top: 45px;
                                left: 124px;
                                color: #666;
                                font-size: 14px;

                                span {
                                    margin-right: 20px;
                                }
                            }

                            .time {
                                color: #333;
                                position: absolute;
                                top: 74px;
                                left: 124px;
                            }

                            .priceItem {
                                position: absolute;
                                left: 340px;
                                display: flex;
                                white-space: nowrap;
                                align-items: center;

                                .icon {
                                    width: 18px;
                                    height: 18px;
                                    font-size: 12px;
                                    @include flex-center;
                                    margin-left: 7px;
                                    border: 1px solid #e0e0e0;
                                    border-radius: 2px;
                                }

                                .price {
                                    color: #ff3333;
                                    font-size: 16px;
                                    margin-right: 6px;
                                }

                                .dw {
                                    font-size: 12px;
                                    color: #333;
                                }

                                &.sPrice {
                                    top: 14px;
                                }

                                &.aPrice {
                                    top: 43px;

                                    .icon {
                                        border-color: #ff3333;
                                        color: #ff3333;
                                    }
                                }
                            }
                        }

                        .itemT {
                            height: 80px;
                            border-bottom: 1px solid #d6d7d9;
                            width: 100%;
                            position: relative;

                            &:last-child {
                                border-bottom: none;
                            }

                            .title {
                                font-size: 16px;
                                position: absolute;
                                top: 15px;
                                left: 0;
                                color: #333;
                            }

                            .address {
                                position: absolute;
                                top: 45px;
                                left: 0;
                                color: #666;
                                font-size: 14px;

                                .mapIcon {
                                    height: 16px;
                                    width: 16px;
                                    transform: translateY(3px);
                                }
                            }

                            .type {
                                padding: 0 8px;
                                height: 22px;
                                border-radius: 2px;
                                font-size: 13px;
                                right: 0;
                                position: absolute;

                                &.type-0 {
                                    top: 5px;
                                    color: #c60a00 !important;

                                    font {
                                        color: #c60a00 !important;
                                    }
                                }

                                &.type-1 {
                                    top: 30px;
                                    color: #c60a00 !important;

                                    font {
                                        color: #c60a00 !important;
                                    }
                                }

                                &.type-2 {
                                    top: 55px;
                                    color: #c60a00 !important;

                                    font {
                                        color: #c60a00 !important;
                                    }
                                }
                            }
                        }
                    }

                    .fanwei {
                        position: absolute;
                        top: 65px;
                        left: 5px;
                        font-size: 12px;
                    }
                }
            }

            .right-top-box {
                flex: 1;
                box-sizing: border-box;
                padding-left: 10px;
                max-width: 1150px;

                .map {
                    width: 100%;
                    height: 651px;
                    background: #eee;
                }

                .map-table {
                    width: 100%;
                    margin-top: 10px;
                    position: relative;
                    border: 1px solid #d6d7d9;

                    .top-tab {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        overflow: hidden;

                        .item {
                            height: 100%;
                            margin-right: 60px;
                            min-width: 100px;
                            color: #333;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            &.active,
                            &:hover {
                                color: #1e2e54;

                                &::after {
                                    width: 100px;
                                    content: "";
                                    height: 4px;
                                    background: #1e2e54;
                                    position: absolute;
                                    bottom: 0;
                                    left: calc(50% - 50px);
                                }
                            }
                        }
                    }

                    .more {
                        position: absolute;
                        bottom: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                        color: #00b6ff;
                        cursor: pointer;
                    }

                    .tabInfo {
                        width: 100%;
                        height: 376px;
                        box-sizing: border-box;
                        overflow: auto;
                        font-size: 16px;

                        .info {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);

                            // grid-template-rows: repeat(auto-fill, 40px);
                            .line {
                                display: flex;
                                height: 40px;

                                .name {
                                    flex: 1;
                                    color: #333;
                                    text-align: right;
                                }

                                .data {
                                    flex: 2;
                                    color: #333333;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom-table {
            margin-top: 20px;

            .tabBox {
                background: #fcfcff;
                border: 1px solid #d6d7d9;
                height: 37px;
            }
        }
    }
}
</style>
